import { NodeProcess } from './configTypes';
import { StatusType } from './services/Models';

declare const process: NodeProcess;

// This is the only non-react-app env variable supported.
const isProduction: boolean = process.env.NODE_ENV === 'production';

// Environment variables besides NODE_ENV must begin with REACT_APP_
//   to be compiled into the webpack for static deploys.
export const semiProxyBaseUrl = process.env.REACT_APP_SEMI_PROXY_URL || 'http://localhost:4201';
export const semiAnonymousProxyBaseUrl = process.env.REACT_APP_SEMI_ANONYMOUS_PROXY_URL || 'http://localhost:4201';
export const buildVersion = process.env.REACT_APP_BUILD_VERSION || 'Number.Hash';
export const useProdNetsuite = !!(process.env.REACT_APP_USE_NETSUITE_PROD) || false;

const secondsToMillis = (seconds: number): number => {
  return seconds * 1000;
};

export const conf = {
  isProduction: isProduction,
  useXHRLogger: false,
  buildVersion: buildVersion,
  pathRoot: '/',
  staticRoot: '/index.html',
  httpRetryInterval: secondsToMillis(3),
  httpRequestTimeout: secondsToMillis(30),
  httpRetryLimit: 0,
  httpRequestTimeoutRetryLimit: 5,
  defaultDownloadedFileName: 'semi-download',
  defaultErrMessage: '<p>An error occurred.<br/><br/>Please submit a support request <a href="https://835472.extforms.netsuite.com/app/site/crm/externalcasepage.nl/compid.835472/.f?formid=27&h=AACffht_l7hreHQQmYrgcvmHTr2zW2LVllQ&redirect_count=1&did_javascript_redirect=T">here</a>.',
  authErrMessage: 'Your session has expired or you are not logged in. Please sign in and try again.',
  programCommitteeId: '20',
  noCompany: "A member company record was not found linked to your email address domain.",
  active: ('Active' as StatusType),
  inactive: ('Inactive' as StatusType),
  filenameHeader: 'x-suggested-filename',
  defaultLanguage: 'en_US',
  defaultCountry: 'US',
  minPassLength: 8,
  maxPassLength: 128,
  minPhoneLength: 7,
  hash: {
    root: '#',
    login: '/login',
    logout: '/logout',
    home: '/home',
    account: '/account',
    accountEditProfile: '/account/editProfile',
    accountEditPassword: '/account/editPassword',
    accountEditMembership: '/account/editMembership',
    accountEditAddress: '/account/editAddress',
    accountEditPhone: '/account/editPhone',
    accountEditPrivacyPolicy: '/account/editPrivacyPolicy',
    accountClose: '/account/close',
    committees: '/account',
    committeesEditStandards: '/account/editStandards',
    committeesEditTech: '/account/editTech',
    accountEditLanguage: '/account/editLanguage',
    committeesEditAffiliate: '/account/editAffiliate',
    committeesAddAffiliate: '/account/addAffiliate',
    standardsBallots: '/standardsBallots',
    myStandardsBallots: '/standardsBallots/myBallots',
    standardsBallotsVoting: '/standardsBallots/myBallots/{0}/{1}',
    productDownload: '/product-downloads'
  },
  netSuite: {
    semiEmailKey: 'https://semi.org/email',
    customerScriptId: useProdNetsuite ? 1509 : 1509,
    customerRecordType: 'customer',
    companiesScriptId: useProdNetsuite ? 1512 : 1512,
    affiliatesScriptId: useProdNetsuite ? 1514 : 1514,
    committeesUpdateScriptId: useProdNetsuite ? 1511 : 1511,
    standardsBallotsScriptId: useProdNetsuite ? 1529 : 1529,
    standardsBallotsVotesScriptId: useProdNetsuite ? 1530 : 1530,
    customerDownloadScriptId: useProdNetsuite ? 1528 : 1528,
  },
  proxyEndpoints: {
    semiNetSuite: '/api/request',
    passwordUpdate: '/api/password',
    logs: '/api/logs',
    fileUpload: '/api/upload',
    semiNetSuiteAnonymous: '/api/request'
  },
  auth0: {
    // url: 'https://semi-org.auth0.com/api/v2/users/USER_ID',
    // domain: 'accounts.semi.org',
    // clientId: '6ybxRpBoarwWLKn5bKLyMzIZiLZyqebo',
    // responseType: 'token id_token'
    url: useProdNetsuite
      ? "https://semi-org.auth0.com/api/v2/users/USER_ID"
      : "https://semi-org-dev.us.auth0.com/api/v2/users/USER_ID",

    domain: useProdNetsuite ? "accounts.semi.org" : "semi-org-dev.us.auth0.com",

    clientId: useProdNetsuite ? "6ybxRpBoarwWLKn5bKLyMzIZiLZyqebo" : "dXsnca8hYv8kXVMDx4EB7fngrr6FBzQ3",

    responseType: useProdNetsuite ? "token id_token" : "token id_token",
  }
};

export const languagesCodesSelect = [
  { name: 'English (U.S.)', value: 'en_US' },
  { name: '中文（简体）Chinese (Simplified)', value: 'zh_CN' },
  { name: '中文（繁體） Chinese (Traditional)', value: 'zh_TW' },
  { name: '日本語 (Japanese)', value: 'ja_JP' },
  { name: '한국어 (Korean)', value: 'ko_KR' },
];

export const codesLanguages = {
  en_US: 'English (U.S.)',
  zh_CN: '中文（简体）Chinese (Simplified)',
  zh_TW: '中文（繁體） Chinese (Traditional)',
  ja_JP: '日本語 (Japanese)',
  ko_KR: '한국어 (Korean)',
};

export const countries = [
  { name: '', value: '' },
  { name: 'United States', value: 'US' },
  { name: 'China', value: 'CN' },
  { name: 'Japan', value: 'JP' },
  { name: 'Korea (the Republic of)', value: 'KR' },
  { name: 'Afghanistan', value: 'AF' },
  { name: 'Aland Islands', value: 'AX' },
  { name: 'Albania', value: 'AL' },
  { name: 'Algeria', value: 'DZ' },
  { name: 'American Samoa', value: 'AS' },
  { name: 'Andorra', value: 'AD' },
  { name: 'Angola', value: 'AO' },
  { name: 'Anguilla', value: 'AI' },
  { name: 'Antarctica', value: 'AQ' },
  { name: 'Antigua and Barbuda', value: 'AG' },
  { name: 'Argentina', value: 'AR' },
  { name: 'Armenia', value: 'AM' },
  { name: 'Aruba', value: 'AW' },
  { name: 'Australia', value: 'AU' },
  { name: 'Austria', value: 'AT' },
  { name: 'Azerbaijan', value: 'AZ' },
  { name: 'Bahamas', value: 'BS' },
  { name: 'Bahrain', value: 'BH' },
  { name: 'Bangladesh', value: 'BD' },
  { name: 'Barbados', value: 'BB' },
  { name: 'Belarus', value: 'BY' },
  { name: 'Belgium', value: 'BE' },
  { name: 'Belize', value: 'BZ' },
  { name: 'Benin', value: 'BJ' },
  { name: 'Bermuda', value: 'BM' },
  { name: 'Bhutan', value: 'BT' },
  { name: 'Bolivia', value: 'BO' },
  { name: 'Bonaire, Saint Eustatius, and Saba', value: 'BQ' },
  { name: 'Bosnia and Herzegovina', value: 'BA' },
  { name: 'Botswana', value: 'BW' },
  { name: 'Bouvet Island', value: 'BV' },
  { name: 'Brazil', value: 'BR' },
  { name: 'British Indian Ocean Territory', value: 'IO' },
  { name: 'Brunei Darussalam', value: 'BN' },
  { name: 'Bulgaria', value: 'BG' },
  { name: 'Burkina Faso', value: 'BF' },
  { name: 'Burundi', value: 'BI' },
  { name: 'Cambodia', value: 'KH' },
  { name: 'Cameroon', value: 'CM' },
  { name: 'Canada', value: 'CA' },
  { name: 'Canary Islands', value: 'IC' },
  { name: 'Cape Verde', value: 'CV' },
  { name: 'Cayman Islands', value: 'KY' },
  { name: 'Central African Republic', value: 'CF' },
  { name: 'Ceuta and Melilla', value: 'EA' },
  { name: 'Chad', value: 'TD' },
  { name: 'Chile', value: 'CL' },
  { name: 'Christmas Island', value: 'CX' },
  { name: 'Cocos (Keeling) Islands', value: 'CC' },
  { name: 'Colombia', value: 'CO' },
  { name: 'Comoros', value: 'KM' },
  { name: 'Congo, Democratic People\'s Republic', value: 'CD' },
  { name: 'Congo, Republic of', value: 'CG' },
  { name: 'Cook Islands', value: 'CK' },
  { name: 'Costa Rica', value: 'CR' },
  { name: 'Cote d\'Ivoire', value: 'CI' },
  { name: 'Croatia/Hrvatska', value: 'HR' },
  { name: 'Cuba', value: 'CU' },
  { name: 'Curacao', value: 'CW' },
  { name: 'Cyprus', value: 'CY' },
  { name: 'Czech Republic', value: 'CZ' },
  { name: 'Denmark', value: 'DK' },
  { name: 'Djibouti', value: 'DJ' },
  { name: 'Dominica', value: 'DM' },
  { name: 'Dominican Republic', value: 'DO' },
  { name: 'East Timor', value: 'TL' },
  { name: 'Ecuador', value: 'EC' },
  { name: 'Egypt', value: 'EG' },
  { name: 'El Salvador', value: 'SV' },
  { name: 'Equatorial Guinea', value: 'GQ' },
  { name: 'Eritrea', value: 'ER' },
  { name: 'Estonia', value: 'EE' },
  { name: 'Ethiopia', value: 'ET' },
  { name: 'Falkland Islands', value: 'FK' },
  { name: 'Faroe Islands', value: 'FO' },
  { name: 'Fiji', value: 'FJ' },
  { name: 'Finland', value: 'FI' },
  { name: 'France', value: 'FR' },
  { name: 'French Guiana', value: 'GF' },
  { name: 'French Polynesia', value: 'PF' },
  { name: 'French Southern Territories', value: 'TF' },
  { name: 'Gabon', value: 'GA' },
  { name: 'Gambia', value: 'GM' },
  { name: 'Georgia', value: 'GE' },
  { name: 'Germany', value: 'DE' },
  { name: 'Ghana', value: 'GH' },
  { name: 'Gibraltar', value: 'GI' },
  { name: 'Greece', value: 'GR' },
  { name: 'Greenland', value: 'GL' },
  { name: 'Grenada', value: 'GD' },
  { name: 'Guadeloupe', value: 'GP' },
  { name: 'Guam', value: 'GU' },
  { name: 'Guatemala', value: 'GT' },
  { name: 'Guernsey', value: 'GG' },
  { name: 'Guinea', value: 'GN' },
  { name: 'Guinea-Bissau', value: 'GW' },
  { name: 'Guyana', value: 'GY' },
  { name: 'Haiti', value: 'HT' },
  { name: 'Heard and McDonald Islands', value: 'HM' },
  { name: 'Holy See (City Vatican State)', value: 'VA' },
  { name: 'Honduras', value: 'HN' },
  { name: 'Hong Kong', value: 'HK' },
  { name: 'Hungary', value: 'HU' },
  { name: 'Iceland', value: 'IS' },
  { name: 'India', value: 'IN' },
  { name: 'Indonesia', value: 'ID' },
  { name: 'Iran (Islamic Republic of)', value: 'IR' },
  { name: 'Iraq', value: 'IQ' },
  { name: 'Ireland', value: 'IE' },
  { name: 'Isle of Man', value: 'IM' },
  { name: 'Israel', value: 'IL' },
  { name: 'Italy', value: 'IT' },
  { name: 'Jamaica', value: 'JM' },
  { name: 'Jersey', value: 'JE' },
  { name: 'Jordan', value: 'JO' },
  { name: 'Kazakhstan', value: 'KZ' },
  { name: 'Kenya', value: 'KE' },
  { name: 'Kiribati', value: 'KI' },
  { name: 'Korea, Democratic People\'s Republic', value: 'KP' },
  { name: 'Kosovo', value: 'XK' },
  { name: 'Kuwait', value: 'KW' },
  { name: 'Kyrgyzstan', value: 'KG' },
  { name: 'Lao, People\'s Democratic Republic', value: 'LA' },
  { name: 'Latvia', value: 'LV' },
  { name: 'Lebanon', value: 'LB' },
  { name: 'Lesotho', value: 'LS' },
  { name: 'Liberia', value: 'LR' },
  { name: 'Libya', value: 'LY' },
  { name: 'Liechtenstein', value: 'LI' },
  { name: 'Lithuania', value: 'LT' },
  { name: 'Luxembourg', value: 'LU' },
  { name: 'Macau', value: 'MO' },
  { name: 'Macedonia', value: 'MK' },
  { name: 'Madagascar', value: 'MG' },
  { name: 'Malawi', value: 'MW' },
  { name: 'Malaysia', value: 'MY' },
  { name: 'Maldives', value: 'MV' },
  { name: 'Mali', value: 'ML' },
  { name: 'Malta', value: 'MT' },
  { name: 'Marshall Islands', value: 'MH' },
  { name: 'Martinique', value: 'MQ' },
  { name: 'Mauritania', value: 'MR' },
  { name: 'Mauritius', value: 'MU' },
  { name: 'Mayotte', value: 'YT' },
  { name: 'Mexico', value: 'MX' },
  { name: 'Micronesia, Federal State of', value: 'FM' },
  { name: 'Moldova, Republic of', value: 'MD' },
  { name: 'Monaco', value: 'MC' },
  { name: 'Mongolia', value: 'MN' },
  { name: 'Montenegro', value: 'ME' },
  { name: 'Montserrat', value: 'MS' },
  { name: 'Morocco', value: 'MA' },
  { name: 'Mozambique', value: 'MZ' },
  { name: 'Myanmar', value: 'MM' },
  { name: 'Namibia', value: 'NA' },
  { name: 'Nauru', value: 'NR' },
  { name: 'Nepal', value: 'NP' },
  { name: 'Netherlands', value: 'NL' },
  { name: 'New Caledonia', value: 'NC' },
  { name: 'New Zealand', value: 'NZ' },
  { name: 'Nicaragua', value: 'NI' },
  { name: 'Niger', value: 'NE' },
  { name: 'Nigeria', value: 'NG' },
  { name: 'Niue', value: 'NU' },
  { name: 'Norfolk Island', value: 'NF' },
  { name: 'Northern Mariana Islands', value: 'MP' },
  { name: 'Norway', value: 'NO' },
  { name: 'Oman', value: 'OM' },
  { name: 'Pakistan', value: 'PK' },
  { name: 'Palau', value: 'PW' },
  { name: 'Panama', value: 'PA' },
  { name: 'Papua New Guinea', value: 'PG' },
  { name: 'Paraguay', value: 'PY' },
  { name: 'Peru', value: 'PE' },
  { name: 'Philippines', value: 'PH' },
  { name: 'Pitcairn Island', value: 'PN' },
  { name: 'Poland', value: 'PL' },
  { name: 'Portugal', value: 'PT' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Qatar', value: 'QA' },
  { name: 'Reunion Island', value: 'RE' },
  { name: 'Romania', value: 'RO' },
  { name: 'Russian Federation', value: 'RU' },
  { name: 'Rwanda', value: 'RW' },
  { name: 'Saint Barthélemy', value: 'BL' },
  { name: 'Saint Helena', value: 'SH' },
  { name: 'Saint Kitts and Nevis', value: 'KN' },
  { name: 'Saint Lucia', value: 'LC' },
  { name: 'Saint Martin', value: 'MF' },
  { name: 'Saint Vincent and the Grenadines', value: 'VC' },
  { name: 'Samoa', value: 'WS' },
  { name: 'San Marino', value: 'SM' },
  { name: 'Sao Tome and Principe', value: 'ST' },
  { name: 'Saudi Arabia', value: 'SA' },
  { name: 'Senegal', value: 'SN' },
  { name: 'Serbia', value: 'RS' },
  { name: 'Seychelles', value: 'SC' },
  { name: 'Sierra Leone', value: 'SL' },
  { name: 'Singapore', value: 'SG' },
  { name: 'Sint Maarten', value: 'SX' },
  { name: 'Slovak Republic', value: 'SK' },
  { name: 'Slovenia', value: 'SI' },
  { name: 'Solomon Islands', value: 'SB' },
  { name: 'Somalia', value: 'SO' },
  { name: 'South Africa', value: 'ZA' },
  { name: 'South Georgia', value: 'GS' },
  { name: 'South Sudan', value: 'SS' },
  { name: 'Spain', value: 'ES' },
  { name: 'Sri Lanka', value: 'LK' },
  { name: 'State of Palestine', value: 'PS' },
  { name: 'St. Pierre and Miquelon', value: 'PM' },
  { name: 'Sudan', value: 'SD' },
  { name: 'Suriname', value: 'SR' },
  { name: 'Svalbard and Jan Mayen Islands', value: 'SJ' },
  { name: 'Swaziland', value: 'SZ' },
  { name: 'Sweden', value: 'SE' },
  { name: 'Switzerland', value: 'CH' },
  { name: 'Syrian Arab Republic', value: 'SY' },
  { name: 'Taiwan', value: 'TW' },
  { name: 'Tajikistan', value: 'TJ' },
  { name: 'Tanzania', value: 'TZ' },
  { name: 'Thailand', value: 'TH' },
  { name: 'Togo', value: 'TG' },
  { name: 'Tokelau', value: 'TK' },
  { name: 'Tonga', value: 'TO' },
  { name: 'Trinidad and Tobago', value: 'TT' },
  { name: 'Tunisia', value: 'TN' },
  { name: 'Turkey', value: 'TR' },
  { name: 'Turkmenistan', value: 'TM' },
  { name: 'Turks and Caicos Islands', value: 'TC' },
  { name: 'Tuvalu', value: 'TV' },
  { name: 'Uganda', value: 'UG' },
  { name: 'Ukraine', value: 'UA' },
  { name: 'United Arab Emirates', value: 'AE' },
  { name: 'United Kingdom', value: 'GB' },
  { name: 'Uruguay', value: 'UY' },
  { name: 'US Minor Outlying Islands', value: 'UM' },
  { name: 'Uzbekistan', value: 'UZ' },
  { name: 'Vanuatu', value: 'VU' },
  { name: 'Venezuela', value: 'VE' },
  { name: 'Vietnam', value: 'VN' },
  { name: 'Virgin Islands, British', value: 'VG' },
  { name: 'Virgin Islands, USA', value: 'VI' },
  { name: 'Wallis and Futuna Islands', value: 'WF' },
  { name: 'Western Sahara', value: 'EH' },
  { name: 'Yemen', value: 'YE' },
  { name: 'Zambia', value: 'ZM' },
  { name: 'Zimbabwe', value: 'ZW' }
];

export const states = {
  us: [
    { name: '', value: '' },
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'Armed Forces Americas', value: 'AA' },
    { name: 'Armed Forces Europe', value: 'AE' },
    { name: 'Armed Forces Pacific', value: 'AP' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'Delaware', value: 'DE' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Puerto Rico', value: 'PR' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' },
  ],
  china: [
    { name: '', value: '' },
    { name: 'Heilongjiang Province', value: 'Heilongjiang Province' },
    { name: 'Jilin Province', value: 'Jilin Province' },
    { name: 'Liaoning Province', value: 'Liaoning Province' },
    { name: 'Neimenggu A. R.', value: 'Neimenggu A. R.' },
    { name: 'Gansu Province', value: 'Gansu Province' },
    { name: 'Ningxia A. R.', value: 'Ningxia A. R.' },
    { name: 'Xinjiang A. R.', value: 'Xinjiang A. R.' },
    { name: 'Qinghai Province', value: 'Qinghai Province' },
    { name: 'Hebei Province', value: 'Hebei Province' },
    { name: 'Henan Province', value: 'Henan Province' },
    { name: 'Shandong Province', value: 'Shandong Province' },
    { name: 'Shanxi Province', value: 'Shanxi Province' },
    { name: 'Shaanxi Province', value: 'Shaanxi Province' },
    { name: 'Jiangsu Province', value: 'Jiangsu Province' },
    { name: 'Zhejiang Province', value: 'Zhejiang Province' },
    { name: 'Anhui Province', value: 'Anhui Province' },
    { name: 'Hubei Province', value: 'Hubei Province' },
    { name: 'Hunan Province', value: 'Hunan Province' },
    { name: 'Sichuan Province', value: 'Sichuan Province' },
    { name: 'Guizhou Province', value: 'Guizhou Province' },
    { name: 'Jiangxi Province', value: 'Jiangxi Province' },
    { name: 'Guangdong Province', value: 'Guangdong Province' },
    { name: 'Guangxi A. R.', value: 'Guangxi A. R.' },
    { name: 'Yunnan Province', value: 'Yunnan Province' },
    { name: 'Hainan Province', value: 'Hainan Province' },
    { name: 'Xizang A. R.', value: 'Xizang A. R.' },
    { name: 'Beijing', value: 'Beijing' },
    { name: 'Shanghai', value: 'Shanghai' },
    { name: 'Tianjin', value: 'Tianjin' },
    { name: 'Chongqing', value: 'Chongqing' },
    { name: 'Fujian Province', value: 'Fujian Province' },
  ],
  japan: [
    { name: '', value: '' },
    { name: 'Hokkaidō', value: 'Hokkaidō' },
    { name: 'Aomori', value: 'Aomori' },
    { name: 'Iwate', value: 'Iwate' },
    { name: 'Miyagi', value: 'Miyagi' },
    { name: 'Akita', value: 'Akita' },
    { name: 'Yamagata', value: 'Yamagata' },
    { name: 'Fukushima', value: 'Fukushima' },
    { name: 'Ibaraki', value: 'Ibaraki' },
    { name: 'Tochigi', value: 'Tochigi' },
    { name: 'Gunma', value: 'Gunma' },
    { name: 'Saitama', value: 'Saitama' },
    { name: 'Chiba', value: 'Chiba' },
    { name: 'Tokyo', value: 'Tokyo' },
    { name: 'Kanagawa', value: 'Kanagawa' },
    { name: 'Niigata', value: 'Niigata' },
    { name: 'Toyama', value: 'Toyama' },
    { name: 'Ishikawa', value: 'Ishikawa' },
    { name: 'Fukui', value: 'Fukui' },
    { name: 'Yamanashi', value: 'Yamanashi' },
    { name: 'Nagano', value: 'Nagano' },
    { name: 'Gifu', value: 'Gifu' },
    { name: 'Shizuoka', value: 'Shizuoka' },
    { name: 'Aichi', value: 'Aichi' },
    { name: 'Mie', value: 'Mie' },
    { name: 'Shiga', value: 'Shiga' },
    { name: 'Kyoto', value: 'Kyoto' },
    { name: 'Osaka', value: 'Osaka' },
    { name: 'Hyōgo', value: 'Hyōgo' },
    { name: 'Nara', value: 'Nara' },
    { name: 'Wakayama', value: 'Wakayama' },
    { name: 'Tottori', value: 'Tottori' },
    { name: 'Shimane', value: 'Shimane' },
    { name: 'Okayama', value: 'Okayama' },
    { name: 'Hiroshima', value: 'Hiroshima' },
    { name: 'Yamaguchi', value: 'Yamaguchi' },
    { name: 'Tokushima', value: 'Tokushima' },
    { name: 'Kagawa', value: 'Kagawa' },
    { name: 'Ehime', value: 'Ehime' },
    { name: 'Kochi', value: 'Kochi' },
    { name: 'Fukuoka', value: 'Fukuoka' },
    { name: 'Saga', value: 'Saga' },
    { name: 'Nagasaki', value: 'Nagasaki' },
    { name: 'Kumamoto', value: 'Kumamoto' },
    { name: 'Ōita', value: 'Ōita' },
    { name: 'Miyazaki', value: 'Miyazaki' },
    { name: 'Kagoshima', value: 'Kagoshima' },
    { name: 'Okinawa', value: 'Okinawa' },
  ]
};

export const countriesStates = {
  US: states.us,
  JP: states.japan,
  CN: states.china
};

export const defaultStates = states.us;
