import React from 'react';
import './RadioButtonComponent.scss';
import Util from '../../util/util';

export type RadioButton = {
  label: string;
  value: string | number;
}

type Props = {
  radioButtons: RadioButton[];
  value: string;
  label?: string;
  error?: string;
  id?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
type State = {};
export default class RadioButtonComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label ? this.props.label : '');
    const groupName = id + '-group';

    return (
      <div id={id} className="radioButtonComponent form-row mb-2">
        <div className="">
          {this.props.radioButtons.map(
            (radioButton: RadioButton, i: number): JSX.Element => {
              return (
                <div key={i} className="radio-button-container w-100 mb-2">
                  <input
                    id={id + '-' + i}
                    name={groupName}
                    type="radio"
                    className={"p-3 " + (this.props.error ? "is-invalid" : "")}
                    onChange={this.props.onChange}
                    value={radioButton.value}
                    checked={radioButton.value === this.props.value}
                  />
                  <label htmlFor={id + '-' + i} className={" " + (this.props.error ? "text-danger" : "")}>
                    {
                      <React.Fragment>
                        <span className="radio"/>
                        <div className="radio-text description-text">
                          {radioButton.label}
                        </div>
                      </React.Fragment>
                    }
                  </label>
                </div>
              );
            }
          )}
          <div className={this.props.error ? "" : "d-none"}>
            <small className="text-danger">{this.props.error}</small>
          </div>
        </div>
      </div>
    );
  }
}
