import React from 'react';

type Props = {
  visible: boolean;
};
type State = {};

export default class SpinnerComponent extends React.Component<Props, State> {
  public state = {};

  public render(): JSX.Element {

    return <div className={'spinnerWrap h-100 ' + (this.props.visible ? 'd-flex' : 'd-none')}>
      <div className="spinner-border text-primary justify-content-center align-self-center m-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>;
  }
}
