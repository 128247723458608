import { CustomerDownloadResponse, DocumentDataModel, CustomerDownloadData } from "./Models";
import { ProxyService } from "./ProxyService";
import { semiAnonymousProxyBaseUrl, conf } from "../config";



export class CustomerDownloadService {

    public static getCustomerDownload(nsData: CustomerDownloadData): Promise<DocumentDataModel[]> {
        return ProxyService.unauthenticatedProxyRequest(
            semiAnonymousProxyBaseUrl + conf.proxyEndpoints.semiNetSuiteAnonymous,
            'get',
            {
                script: conf.netSuite.customerDownloadScriptId,
                email: nsData.email,
                accessCode: nsData.accessCode
            },
            null
        ).then((response: CustomerDownloadResponse): Promise<DocumentDataModel[]> => {
            // Group ballot line items in same committee cycle as ballots.
            const customerDocument = response.documentData.map((documentData: DocumentDataModel): DocumentDataModel => {


                return {
                    ...documentData,
                    documentDownload: response.documentData[0].documentDownload
                };
            });
            return Promise.resolve(customerDocument);
        });
    }
}