import {conf} from '../config';

export default class Validators {
  public static formError = 'Form Error';//'Please complete or correct the required fields above.';
  public static requiredError = 'Required Error';//'This field is required.';
  public static phoneError = 'Enter a valid phone with at least ' + conf.minPhoneLength + ' digits.';
  public static passwordError = 'Enter a valid password between ' + conf.minPassLength
    + '-' + conf.maxPassLength
    + ' chars with at least three of: lower (a-z), upper (A-Z), numbers (0-9), and special (!@#$%^&*)';
  public static passwordMatchError = 'Passwords do not match. Please correct and try again.';
  public static standardsBallotsCommentsError = 'Please enter comments or upload a file.';

  public static specialChars = ['!', '@', '#', '$', '%', '^', '&', '*'];

  public static isNotEmpty(str: string | null | undefined): boolean {
    return !!(str && str.trim().length > 0);
  }

  public static isValidPhone(str: string | null | undefined): boolean {
    return !!str && Validators.isNotEmpty(str) && str.length >= conf.minPhoneLength;
  }

  public static isValidPassword(str: string): boolean {
    if (!Validators.isNotEmpty(str)
      || str.length < conf.minPassLength
      || str.length > conf.maxPassLength) {
      return false;
    }

    let hasLower = false;
    let hasUpper = false;
    let hasNum = false;
    let hasSpecial = false;

    for (let i = 0; i < str.length; i++) {
      const ch = str.charAt(i);

      if (/[a-z]/.test(ch)) {
        hasLower = true;
      }

      if (/[A-Z]/.test(ch)) {
        hasUpper = true;
      }

      if (!isNaN(parseInt(ch, 10))) {
        hasNum = true;
      }

      for (const s of Validators.specialChars) {
        if (ch === s) {
          hasSpecial = true;
          break;
        }
      }
    }

    return [hasLower, hasUpper, hasNum, hasSpecial].filter((b) => b).length >= 3;
  }

}
