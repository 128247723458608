import React from 'react';
import './StandardsCommitteesFormComponent.scss';
import {CommitteeModel, ProfileModel} from '../../../services/Models';
import FormComponent from '../../common/FormComponent';
import CheckboxComponent from '../../common/CheckboxComponent';
import AutoCompleteComponent, {InputListValue} from '../../common/AutoCompleteComponent';
import {conf} from '../../../config';
import {ProfileService} from '../../../services/ProfileService';
import {LoggerService} from '../../../services/LoggerService';

const logger = LoggerService.getLogger('StandardsCommitteesFormComponent');

interface FieldErrors {
  committee?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class StandardsCommitteesFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
  };

  public componentDidMount(): void {
    this.setState({editedProfile: this.props.profileModel});
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    const hasProgramCom = ProfileService.hasProgramCommittee(p.committee.standards);
    const programComActive = ProfileService.hasActiveProgramCommittee(p.committee.standards);
    const searchableCommittees = ProfileService.committeesToInputValues(p.committee.standards.filter(
        (comm) => comm.committeeid !== conf.programCommitteeId && comm.status === conf.inactive));

    return <div id="committeesFormComponent">

        <FormComponent
            title={'Update Committees'}
            buttonText={hasProgramCom ? 'Update Committees' : ''}
            validate={this.validate}
            onSave={(): Promise<void> => {
              return this.props.onSave(this.state.editedProfile);
            }}
        >

          <div className="row">
            <div className="col-9 text-capitalize">
              <p><strong>Program Membership</strong></p>
            </div>
            {
              hasProgramCom ?
                  <div className="col-3"><CheckboxComponent
                      id={'edit-comm-main'}
                      checked={programComActive}
                      label={''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        const comm = ProfileService.findProgramCommittee(p.committee.standards);
                        if (comm) {
                          comm.status = e.target.checked ? conf.active : conf.inactive;
                          this.setState({editedProfile: {...p}});
                        }
                      }}
                  />
                  </div>
                  : <div className="col-12">
                    <p>Unable to provide committee enrollment, please try again later.</p>
                  </div>
            }
          </div>
          {
            programComActive && <React.Fragment>
              <AutoCompleteComponent
                  label="Search Committees"
                  clearOnSelected={true}
                  listValues={searchableCommittees}
                  error={this.state.fieldErrors.committee}
                  onSelected={this.selectCommittee}/>

              {p.committee.standards.map((comm: CommitteeModel, i): JSX.Element | null => {
                if (comm.committeeid === conf.programCommitteeId) {
                  return null;
                }

                if (comm.status !== conf.active) {
                  return null;
                }

                return <div key={i} className="row">
                  <div className="col-9 text-capitalize">
                    <p><strong>{comm.committeename}</strong></p>
                  </div>
                  <div className="col-3">
                    <CheckboxComponent
                        id={'edit-comm' + i}
                        checked={true}
                        label={''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                          p.committee.standards[i].status = e.target.checked ? conf.active : conf.inactive;
                          this.setState({editedProfile: {...p}});
                        }}
                    />
                  </div>
                </div>;
              })}
            </React.Fragment>
          }
        </FormComponent>  
        
    </div>;
  }


  private validate = (): boolean => {
    const fieldErrors: FieldErrors = {};

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };

  private selectCommittee = (listValue: InputListValue<CommitteeModel>): void => {
    if (!listValue.data) {
      logger.error('Missing committee');
      return;
    }

    const p = this.state.editedProfile;
    const comms = p.committee.standards;

    for (const comm of comms) {
      if (comm.committeeid === listValue.data.committeeid) {
        comm.status = conf.active;
        break;
      }
    }

    this.setState({editedProfile: {...p, committee: {...p.committee, standards: comms}}});
  };
}
