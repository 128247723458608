import React from 'react';
import './AffiliateFormComponent.scss';
import {AffiliateModel, AffiliateResponse, ProfileModel} from '../../../services/Models';
import FormComponent from '../../common/FormComponent';
import AutoCompleteComponent, {InputListValue} from '../../common/AutoCompleteComponent';
import {ProfileService} from '../../../services/ProfileService';
import {HttpError} from '../../../services/HttpService';
import Validators from '../../../util/validators';
import Util from '../../../util/util';
import {conf} from '../../../config';
import {LoggerService} from '../../../services/LoggerService';
import LoadingErringComponent from '../../common/LoadingErringComponent';
import {Translation} from 'react-i18next';
import {TFunction} from 'i18next';
const logger = LoggerService.getLogger('AffiliateFormComponent');

interface FieldErrors {
  company?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
  affiliateListValues: Array<InputListValue<AffiliateModel>>;
  httpError: HttpError | null;
};
export default class AffiliateFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    affiliateListValues: [],
    fieldErrors: {},
    httpError: null
  };

  public componentDidMount(): void {
    this.setState({editedProfile: this.props.profileModel});

    ProfileService.getAffiliates()
    .then((affiliateRes: AffiliateResponse): void => {
      if (affiliateRes && Object.keys(affiliateRes).length > 0) {
        this.setState({
          affiliateListValues: ProfileService.affiliatesToInputValues(affiliateRes)
        });
      } else {
        this.setState({
          httpError: new HttpError(500, conf.defaultErrMessage)
        });
      }
    })
    .catch((_: HttpError): void => {
      // Ignored
    });
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;

    let initialSelectedValue: InputListValue<AffiliateModel> = {id: '', value: '', data: null};
    if (p.affiliate && p.affiliate.affiliateid) {
      initialSelectedValue = {id: p.affiliate.affiliateid, value: p.affiliate.affiliateName, data: p.affiliate};
    }

    if (this.state.affiliateListValues.length < 1) {
      return <LoadingErringComponent error={this.state.httpError ? this.state.httpError.toString() : ''}
                                     loading={!this.state.httpError}/>;
    }
    return <Translation>{(t: TFunction): JSX.Element => {
    return <div id="affiliateFormComponent">
      <FormComponent
          title={t('Update Affiliate')}
          buttonText={t('Update Affiliate')} validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.editedProfile);
          }}
      >
        <AutoCompleteComponent
            label={t("Select an Affiliate")}
            initialSelectedValue={initialSelectedValue}
            listValues={this.state.affiliateListValues}
            // error={this.state.fieldErrors.company}
            error= {t(this.state.fieldErrors.company || "")}
            onSelected={(listValue: InputListValue<AffiliateModel>): void => {
              const affiliate = listValue.data;
              if (!affiliate || !affiliate.affiliateid) {
                logger.error('Missing affiliate/ID in list of affiliates.');
                return;
              }

              this.setState({
                editedProfile: {
                  ...p,
                  affiliate: affiliate
                }
              });
            }}
        />

        <div className="form-row pl-5 pr-5 mb-2">
          <p>{t('Dont see your affiliate?')} <a href={Util.routeHashToURL(conf.hash.committeesAddAffiliate)}>{t('Add one here')}</a></p>
          
        </div>

      </FormComponent>
    </div>;
     }}</Translation>;
  }

  private validate = (): boolean => {
    const a = this.state.editedProfile.affiliate;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(a.affiliateid)) {
      fieldErrors.company = Validators.requiredError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
