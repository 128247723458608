import React from 'react';
import {Auth0Service} from '../services/Auth0Service';
import SpinnerComponent from './SpinnerComponent';
import {Redirect} from 'react-router';
import {conf} from '../config';

type Props = {};
type State = {
  loggedOut: boolean;
};

export default class LogoutComponent extends React.Component<Props, State> {
  public state = {
    loggedOut: false
  };

  public componentDidMount(): void {
    Auth0Service.logout();
  }

  public render(): JSX.Element {
    if (this.state.loggedOut) {
      return <Redirect exact from={conf.hash.logout} to={conf.hash.home}/>;
    } else {
      return <SpinnerComponent visible={true}/>;
    }
  }
}
