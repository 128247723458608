import React from 'react';
import './ErrorComponent.scss';

type Props = {
  message: string;
};
type State = {};
export default class ErrorComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    return <div className="errorComponent container mt-3 h-100" role="alert">
      <div className="row">
        <div className="col-12">
          <div className="alert alert-danger" role="alert"
               dangerouslySetInnerHTML={{__html: this.props.message}}
          >
          </div>
        </div>
      </div>
    </div>;
  }
}
