import React from 'react';
import './StandardsPrivacyPolicyFormComponent.scss';
import {ProfileModel} from '../../../services/Models';
import Validators from '../../../util/validators';
import FormComponent from '../../common/FormComponent';
import CheckboxComponent from '../../common/CheckboxComponent';
import {Translation} from 'react-i18next';
import {TFunction} from 'i18next';
interface FieldErrors {
  PrivacyOne?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class PrivacyPolicyFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {}
  };

  public componentDidMount(): void {
    this.setState({editedProfile: this.props.profileModel});
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    return <Translation>{(t: TFunction): JSX.Element => {
    return <div id="standardsPrivacyPolicyFormComponent">
      <FormComponent
          title={'STANDARDS POLICIES AND NOTICES'}
          buttonText="Save" validate={this.validate}
          onSave={(): Promise<void> => {
            this.setState({editedProfile: {...p, committee: {...p.committee, memberAgree: true}}});
              return this.props.onSave(this.state.editedProfile);
          }}
      >
        <div className="row">
              <div className="col-12 ">
                <h6>COPYRIGHT ASSIGNMENT</h6>
                <p>SEMI Standards and Guidelines are collectively prepared works published by SEMI as a service to the industry. These works are copyrighted in SEMI’s name upon balloting and publication as SEMI International Standards. Accordingly, SEMI requires that you assign to SEMI copyright of standards and guidelines resulting from your participation in the development of SEMI International Standards. Your signature below assigns copyright of such documents to SEMI. All rights in any original unmodified drafts remain with the author. Reformatting of published SEMI standards and guidelines, their distribution outside the principal author’s company, and all other reproduction and/or distribution is prohibited without the written consent of SEMI.</p>  

              
              </div>
              <div className="col-12 ">
              <h6>INTELLECTUAL PROPERTY</h6>
              <p>To better anticipate and avoid unknowing incorporation of patented technology, copyrighted items, or trademarks into a SEMI standard or safety guideline, participants in the development of SEMI standards and safety guideline are requested to observe the following code of behavior.
                  <br></br>
                  <br></br>
                  •  Responsibility to Report Relevant Intellectual Property - All participants in the SEMI Standards Program have the responsibility to make known to the group in which they participate any non-confidential patented technology copyrighted items, or trademarks, including issued patents and published patent applications, of which they have knowledge and which might be required to be used in order to comply with the standard or safety guideline being developed by the group.
                  <br></br>
                  <br></br>
                  •  Prohibition Against Intentionally Concealing Information - It should be noted that intentional concealing of any intellectual property rights while knowing they could have an effect on the document under development is not only unprofessional, but also it may render the intellectual property rights unenforceable in the future.
                  <br></br>
                  <br></br>
                  •  Recording in Minutes - Contents of discussions relating to intellectual property rights, including patent rights and pending patent applications should be recorded in the minutes of the TC Chapter having jurisdiction over the document being developed. If such discussions take place in a subcommittee or task force, they should be recorded in the report of the subcommittee or task force and appended to the minutes of the TC Chapter meeting at which the report is made. This provides a public record of the discussions and disclosures as well as a basis for any cautionary notes to be added to the document
                  <br></br>
                  <br></br>
                  •  Self-Protection - Participants in the SEMI Standards Program should guard against inclusion of technical ideas discussed in these open forums in future applications or in amendments of existing patent applications.
                  
                </p>
                </div>
                <div className="col-12 ">
                <h6>NOTICE: USE OF USER INFORMATION</h6>
                <p>SEMI Standards reserves the right to compile, save, use within the scope of SEMI Standard's activities, and analyze any and all User data (registration data, use history, etc.). SEMI Standards intends to use such User data for internal purposes only, including without limitation for the purposes of responding to User’s requests for information and for contacting User. SEMI Standards may also provide User data to regional offices or departments within SEMI, the parent organization of SEMI Standards.
                </p>
                <p>
                SEMI Standards will, to the extent possible, control User information, and, except as otherwise set forth herein, not disclose such User information to third parties. However, in the event disclosure of any User information by SEMI Standards is required by law, SEMI Standards may disclose such User information without the consent of User.
                </p>
              </div>
                <div className="mt-5 text-center">
                  <CheckboxComponent
                      label="I agree with the above policy and terms of use."
                      checked={this.state.editedProfile.committee.memberAgree}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({ editedProfile: { ...p, committee: {...p.committee, memberAgree: e.target.checked}}});
                      }}
                  />
                </div>
          </div>

      </FormComponent>
    </div>;}}</Translation>;

  }
  
  private validate = (): boolean => {
    const p = this.state.editedProfile;
    const fieldErrors: FieldErrors = {};

    if (!p.committee.memberAgree) {
      fieldErrors.PrivacyOne = Validators.requiredError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
