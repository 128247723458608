import {AxiosResponse} from 'axios';

export enum HTTP_ERROR_CODE {
  SYSTEM = 500,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401
}

export type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

export type HttpHeaders = {
  [header: string]: string | string[];
};

export type HttpParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export interface ProxyRequestBody {
  method: HttpMethod;
  payload?: any;
  pathParams: {[key: string]: string | number};
}

export type HttpRequestOptions = {
  headers?: HttpHeaders;
  pathParams?: HttpParams;
  queryParams?: HttpParams;
  externalUrl?: string;
  httpRetryLimit?: number;
  httpTimeout?: number;
  responseType?: 'arraybuffer' | 'blob';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parser?: (axiosRes: AxiosResponse) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};
