import React from 'react';
import FormComponent from '../../common/FormComponent';
import Validators from '../../../util/validators';
import InputComponent from '../../common/InputComponent';
import {AffiliateModel} from '../../../services/Models';
import {Translation} from 'react-i18next';
import {TFunction} from 'i18next';

interface FieldErrors {
  affiliateName?: string;
}

type Props = {
  onSave: (affiliate: AffiliateModel) => Promise<void>;
};
type State = {
  affiliate: AffiliateModel;
  fieldErrors: FieldErrors;
};
export default class AddAffiliateFormComponent extends React.Component<Props, State> {
  public state: State = {
    affiliate: {
      affiliateid: '',
      affiliateName: '',
      affiliateCode: '',
    },
    fieldErrors: {}
  };
  
  public render(): JSX.Element {
    return <Translation>{(t: TFunction): JSX.Element => {
    return <div id="addAffiliateFormComponent">
      <FormComponent
          title={t('Add Affiliate')}
          buttonText={t("Submit")}
          validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.affiliate);
          }}
      >

        <InputComponent
            label={t('Affiliate Name')}
            value={this.state.affiliate.affiliateName}
            // error={this.state.fieldErrors.affiliateName}
            error= {t(this.state.fieldErrors.affiliateName || "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              this.setState({
                affiliate: {
                  ...this.state.affiliate,
                  affiliateName: e.target.value
                }
              });
            }}/>

      </FormComponent>
    </div>;
    }}</Translation>;
  }

  private validate = (): boolean => {
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(this.state.affiliate.affiliateName)) {
      fieldErrors.affiliateName = Validators.requiredError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
