import React from 'react';
import './SelectComponentStandards.scss';
import Util from '../../util/util';
import { TFunction } from 'i18next';
import { Translation } from 'react-i18next';

export interface SelectOption {
    name: string;
    value: string;
}

type Props = {
    label: string;
    value: string;
    placeholder?: string;
    error?: string;
    className?: string;
    id?: string;
    selectOptions: SelectOption[];
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};
type State = {};
export default class SelectComponentStandards extends React.Component<Props, State> {
    public state: State = {};

    public render(): JSX.Element {
        const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label);

        return <Translation>{(t: TFunction): JSX.Element => {
            return <div className={'selectComponent mb-2 ' + this.props.className ? this.props.className : ''}>
                <div className="form-group w-100">
                    <label htmlFor={id} className={(this.props.error ? 'text-danger' : '')}><b>{this.props.label}</b></label>
                    <select
                        id={id}
                        name={id}
                        placeholder={this.props.placeholder || this.props.label}
                        className={'form-control w-100 pl-3 pr-3 ' + (this.props.error ? 'is-invalid' : '')}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        disabled={this.props.disabled}
                    >
                        {
                            this.props.selectOptions.map((selectionOption: SelectOption, i: number): JSX.Element => {
                                return <option key={i} className={'text-capitalize'} value={selectionOption.value}>
                                    {t(selectionOption.name)}
                                </option>;
                            })
                        }
                    </select>
                    <div className={(this.props.error ? '' : 'd-none')}>
                        <small className="text-danger">
                            {this.props.error}
                        </small>
                    </div>
                </div>
            </div>;
        }}</Translation>;
    }
}
