import React from "react";
import "./MembershipFormComponent.scss";
import { CompanyModel, CompanyResponse, ProfileModel } from "../../../services/Models";
import FormComponent from "../../common/FormComponent";
import AutoCompleteComponent, { InputListValue } from "../../common/AutoCompleteComponent";
import { ProfileService } from "../../../services/ProfileService";
import { HttpError } from "../../../services/HttpService";
import Validators from "../../../util/validators";
import { LoggerService } from "../../../services/LoggerService";
import LoadingErringComponent from "../../common/LoadingErringComponent";
import { conf } from "../../../config";
import { TFunction } from "i18next";
import { Translation } from "react-i18next";

const logger = LoggerService.getLogger("MembershipFormComponent");

interface FieldErrors {
  company?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
  companyListValues: Array<InputListValue<CompanyModel>>;
  httpError: HttpError | null;
};
export default class MembershipFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    companyListValues: [],
    fieldErrors: {},
    httpError: null,
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });

    ProfileService.getCompanies()
      .then((companyRes: CompanyResponse): void => {
        // if (companyRes && companyRes.companies && companyRes.companies.length > 0) {
        if (companyRes && companyRes.companies && companyRes.companies.length > 0) {
          this.setState({
            companyListValues: ProfileService.companiesToInputValues(companyRes.companies),
          });
        } else if (companyRes.companies.length === 0) {
          this.setState({
            httpError: new HttpError(500, conf.noCompany),
          });
        } else {
          this.setState({
            httpError: new HttpError(500, conf.defaultErrMessage),
          });
        }
      })
      .catch((httpError: HttpError): void => {
        this.setState({ httpError: httpError });
      });
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    const c = this.state.editedProfile.customer;

    let initialSelectedValue: InputListValue<CompanyModel> = { id: "", value: "", data: null };
    if (c.memberCompanyName && c.memberCompanyID) {
      initialSelectedValue = {
        id: c.memberCompanyID,
        value: c.memberCompanyName,
        data: {} as CompanyModel,
      };
    }

    if (this.state.companyListValues.length < 1 && !this.state.httpError) {
      return <LoadingErringComponent loading={true} error={""} />;
    }
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
    return (
      <div id="membershipFormComponent">
        <FormComponent
          title={t("Update Membership")}
          buttonText={t("Update Membership")}
          validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.editedProfile);
          }}
        >
          {!this.state.httpError ? (
          <div className="form-row pl-5 pr-5 mb-2">
            <p>{t("Company")}: {c.memberCompanyName}</p>
            <p>{t("Membership Status")}: {c.memberStatus}</p>
            <p>{t('Member Dues Paid Through')}: {c.duesPaidThru}</p>
          </div>
          ):<p></p>
          }
          {!this.state.httpError ? (
            <AutoCompleteComponent
              label={t('Update Company')}
              initialSelectedValue={initialSelectedValue}
              listValues={this.state.companyListValues}
              // error={this.state.fieldErrors.company}
              error= {t(this.state.fieldErrors.company || "")}
              onSelected={(listValue: InputListValue<CompanyModel>): void => {
                if (!listValue.data || !listValue.data.memberCompanyID) {
                  logger.error("Missing company in autocomplete result.");
                  return;
                }

                this.setState({
                  editedProfile: {
                    ...p,
                    customer: {
                      ...c,
                      memberCompanyID: listValue.data.memberCompanyID,
                      memberCompanyName: listValue.data.memberCompanyName,
                      memberLocalCompanyName: listValue.data.memberLocalCompanyName,
                      memberPhoneticCompanyName: listValue.data.memberPhoneticCompanyName,
                    },
                  },
                });
              }}
            />
          ) : (
            <LoadingErringComponent loading={false} error={this.state.httpError.message} />
          )}
        </FormComponent>
      </div>);
        }}
      </Translation>
    );
  }

  private validate = (): boolean => {
    const c = this.state.editedProfile.customer;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(c.memberCompanyID)) {
      fieldErrors.company = Validators.requiredError;
    }

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };
}
