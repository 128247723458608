import React from 'react';
import './AllStandardsBallotsComponent.scss';
import { StandardsBallotModel, CommitteeCycleModel } from '../../../services/Models';
import { conf } from '../../../config';
import CardComponent from '../../common/CardComponent';
import CardFieldRowComponent from '../../common/CardFieldRowComponent';
import { BallotsService } from '../../../services/BallotsService';
import SelectComponentStandards from '../../common/SelectComponentStandards';

type Props = {
  committeeCycles: CommitteeCycleModel[];
  votingAnnouncements: string[] | null;
};
type State = {
  selectedCommittee: string;
  selectedCycle: string;
  selectedCommitteeCycle: CommitteeCycleModel | null;
};

export default class AllStandardsBallotsComponent extends React.Component<Props, State> {
  public state: State = {
    selectedCommittee: '',
    selectedCycle: '',
    selectedCommitteeCycle: null,
  };

  public render(): JSX.Element {
    const cycle = this.state.selectedCycle;
    const committee = this.state.selectedCommittee;
    const committeeCycle = this.state.selectedCommitteeCycle;

    return <React.Fragment>
      <div id='allStandardsBallotsComponent' className='container mt-3'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <CardComponent
              title='Standards Ballots'
              buttonText='Filter'
              onButtonClick={this.filterBallots}>

              <div className=' description-text'>
                <p>
                  Technical ballots are used by the SEMI Standards Program to review the
                  technical content review of documents under development. Ballots are
                  considered the major step in the formal approval process prior to
                  publishing of a new SEMI Standard or a revision of an existing SEMI Standard.
                </p>

                <p>
                  For those new to the SEMI International Standards Program, please refer to the
                  section About Standards, as well as the SEMI Standards Regulations, which are
                  the rules by which the program is governed.
                </p>

                <p>
                  Use the pull-down menu below to browse an individual committee's technical ballots.
                </p>
              </div>

              <SelectComponentStandards
                label='Ballot Period'
                value={cycle}
                selectOptions={BallotsService.committeeCyclesToCycleSelectOptions(this.props.committeeCycles)}
                placeholder='Select Ballot Period'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  this.setState({
                    selectedCycle: e.target.value,
                    selectedCommittee: ''
                  });
                }}>
              </SelectComponentStandards>

              <SelectComponentStandards
                label='Ballot Committee'
                value={committee}
                selectOptions={BallotsService.committeeCyclesToCommitteeSelectOptions(
                  this.props.committeeCycles.filter((cc: CommitteeCycleModel): boolean => cc.period === cycle))}
                disabled={cycle === ''}
                placeholder='Select Committee'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  this.setState({
                    selectedCommittee: e.target.value
                  });
                }}>
              </SelectComponentStandards>

            </CardComponent>
          </div>

          <div id='voting-card' className='col- 12 col-lg-6'>
            <CardComponent
              title='Voting Announcements'
              buttonText='Vote'
              editRoute={conf.hash.myStandardsBallots}
            >
              {
                this.props.votingAnnouncements &&
                this.props.votingAnnouncements
                  .map((announcement: string,
                    i: number, arr: string[]): JSX.Element =>
                    <div key={i}>
                      <CardFieldRowComponent
                        value={announcement}
                        includeDivider={arr.length - 1 !== i} />
                    </div>
                  )
              }
            </CardComponent>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-lg-12'>
            <CardComponent title='Standards Ballots'>
              {committeeCycle ?
                committeeCycle.standardsBallot.map((ballot: StandardsBallotModel, index: number): JSX.Element => {

                  const title = ballot ?
                    [committeeCycle.committee, committeeCycle.period, 'Ballots'].join(' ') : '';

                  return <div key={index}>
                    <CardFieldRowComponent title={title} subtitle={ballot.subtitle} />

                    <a href={ballot.fileURL} download>{ballot.fileName}</a>

                    <div className='description-text'>
                      {
                        ballot.details && <ul className='bullet-list'>
                          {ballot.details.map((detail, i): JSX.Element | null =>
                            <li key={i}><span>{detail}</span>
                            </li>)}
                        </ul>
                      }
                    </div>

                    <div className='card-divider-dashed w-100 mb-3' />
                  </div>
                })
                : <React.Fragment>
                  <CardFieldRowComponent subtitle={
                    'Please select a committee and a cycle above and click "Filter" to view available ballots.'
                  } />

                  <div className='card-divider-dashed w-100 mb-3' />
                </React.Fragment>
              }
            </CardComponent>
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  private filterBallots = (): void => {
    const committee = this.state.selectedCommittee;
    const cycle = this.state.selectedCycle;

    if (committee && cycle) {
      const committeeCycle: CommitteeCycleModel | undefined =
        (this.props.committeeCycles.find((c: CommitteeCycleModel): boolean => {
          return c.committee === this.state.selectedCommittee &&
            c.period === this.state.selectedCycle;
        }));

      this.setState({
        selectedCommitteeCycle: committeeCycle ? committeeCycle : null
      });
    } else {
      this.setState({
        selectedCommitteeCycle: null
      })
    }
  };
}
