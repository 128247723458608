export const translations = {
  'en_US': {
    translation: {
      'EmailMessage' : 'Email domain is used to determine SEMI membership affiliation for online activities and cannot be changed. You may close this account and create a new one if you wish to receive email at a new address.',
      'LanguageMessage' : 'Change your language.',
      'Privacy Description': 'View and agree to our privacy policy.',
      'Dont see your affiliate?' : "Don't see your affiliate?",
      'Affiliate Message' : 'Please choose an affiliate company to get started.',
      'Form Error': 'Please complete or correct the required fields above.',
      'Required Error' :'This field is required.',
    }
  },
  'zh_CN': {
    translation: {
      'Local First Name': '本地名字',
      'Local Last Name': '本地姓氏',
      'Local Title': '本地产权',
      'Local Company': '本地公司',
      'Local Division': '本地公司',
      'Title': '标题',
      'First Name': '名',
      'Last Name': '姓',
      'Profile': '轮廓',
      'Full Name' : '全名',
      'Company Name': '公司名称',
      'Job Title': '职称',
      'Email' : '电子邮件',
      'EmailMessage': '电子邮件域名用于确定在线活动的会员隶属关系，且无法更改。如果您希望通过新地址接收电子邮件，您可以关闭此帐户并创建一个新帐户。',
      'Account Options': '账户选项',
      'Language': '语言',
      'Language Preference': '语言首选项',
      'LanguageMessage' : '改变你的语言。',
      'Privacy Policy': '隐私政策',
      'Privacy Description': '查看并同意我们的隐私政策。',
      'Membership' :"会员资格",
      'Membership Type':"会员类型",
      'Membership Status': "会员资格",
      'Member Company': "会员公司",
      'Expiration Date': "截止日期",
      'Address': "地址",
      'Home Address': "家庭地址",
      'Update': '更新',
      'Phone' :'电话',
      'Update Profile' : '更新个人信息',
      'Update Membership': '更新会员资格',
      'Company' : '公司',
      'Member Dues Paid Through' :'会员会费通过',
      'Update Company' : '更新公司',
      'Update Address' : '更新地址',
      'Addressee' : '收件人',
      'Address Line' : '地址栏',
      'City' : '城市',
      'Country / Region' : '国家/地区',
      'State / Province' : '州/省',
      'Zip Code' : '邮政编码',
      'Update Phone' : '邮政编码',
      'Standards Committees' : '标准委员会',
      'Update Affiliate' : '更新会员',
      'Select an Affiliate' : '选择一个附属机构',
      'Dont see your affiliate?' : '没有看到您的会员？',
      'Add one here' : '在此添加一个',
      'Affiliate Message' : '请选择一个附属公司来开始。',
      'Add Affiliate' : '添加会员',
      'Affiliate Name' : '关联公司名称',
      'Submit' : '提交',
      'Form Error': '请填写或更正上面的必填字段。',
      'Required Error' :'此字段是必需的',
    }
  },
  'zh_TW': {
    translation: {
      'Local First Name': '名字',
      'Local Last Name': '姓氏',
      'Local Title': '職稱',
      'Local Company': '公司名',
      'Local Division': '部門',

      'Title': '標題',
      'First Name': '名',
      'Last Name': '苗字',
      'Profile': '輪廓',
      'Full Name' : '全名',
      'Company Name': '公司名稱',
      'Job Title': '職稱',
      'Email' : '電子郵件',
      'EmailMessage': '電子郵件網域用於確定線上活動的會員隸屬關係，且無法變更。如果您希望透過新地址接收電子郵件，您可以關閉此帳戶並建立新帳戶。',
      'Account Options': '帳戶選項',
      'Language': '語言',
      'Language Preference': '語言首選項',
      'LanguageMessage' : '改變你的語言。',
      'Privacy Policy': '隱私權政策',
      'Privacy Description': '查看並同意我們的隱私權政策。',
      'Membership' :"會員資格",
      'Membership Type':"會員類型",
      'Membership Status': "會員資格",
      'Member Company': "會員公司",
      'Expiration Date': "截止日期",
      'Address': "地址",
      'Home Address': "家庭地址",
      'Update': '更新',
      'Phone' :'電話',
      'Update Profile' : '更新個人訊息',
      'Update Membership': '更新會員資格',
      'Company' : '公司',
      'Member Dues Paid Through' :'會員會費通過',
      'Update Company' : '更新公司',
      'Update Address' : '更新地址',
      'Addressee' : '收件者',
      'Address Line' : '收件者',
      'City' : '城市',
      'Country / Region' : '國家/地區',
      'State / Province' : '州/省',
      'Zip Code' : '郵遞區號',
      'Update Phone' : '更新手機',
      'Standards Committees' : '標準委員會',
      'Update Affiliate' : '更新會員',
      'Select an Affiliate' : '選擇一個附屬機構',
      'Dont see your affiliate?' : '沒有看到您的會員?',
      'Add one here' : '在此添加一個',
      'Affiliate Message' : '請選擇一個附屬公司來開始。',
      'Add Affiliate' : '新增會員',
      'Affiliate Name' : '關聯公司名稱',
      'Submit' : '提交',
      'Form Error': '請填寫或更正上面的必填欄位。',
      'Required Error' :'此欄位是必需的。',


      // States/Provinces
      'Heilongjiang Province': '黑龙江省',
      'Jilin Province': '吉林省',
      'Liaoning Province': '辽宁省',
      'Neimenggu A. R.': '内蒙古',
      'Gansu Province': '甘肃省',
      'Ningxia A. R.': '宁夏回族自治区',
      'Xinjiang A. R.': '新疆维吾尔族自治区',
      'Qinghai Province': '青海省',
      'Hebei Province': '河北省',
      'Henan Province': '河南省',
      'Shandong Province': '山东省',
      'Shanxi Province': '山西省',
      'Shaanxi Province': '陕西省',
      'Jiangsu Province': '江苏省',
      'Zhejiang Province': '浙江省',
      'Anhui Province': '安徽省',
      'Hubei Province': '湖北省',
      'Hunan Province': '湖南省',
      'Sichuan Province': '四川省',
      'Guizhou Province': '贵州省',
      'Jiangxi Province': '江西省',
      'Guangdong Province': '广东省',
      'Guangxi A. R.': '广西壮族自治区',
      'Yunnan Province': '云南省',
      'Hainan Province': '海南省',
      'Xizang A. R.': '西藏藏族自治区',
      'Beijing': '北京市',
      'Shanghai': '上海市',
      'Tianjin': '天津市',
      'Chongqing': '重庆市',
      'Fujian Province': '福建省',
    }
  },
  'ja_JP': {
    translation: {
      
      'Local First Name': '名',
      'Phonetic First Name': 'ふりがな',
      'Local Last Name': '姓',
      'Phonetic Last Name': 'ふりがな',
      'Local Title': '役職',
      'Local Company': '会社',
      'Phonetic Company': 'かいしゃ',


      'Title': 'タイトル',
      'Local Division': '部署',
      'First Name': 'ファーストネーム',
      'Last Name': '苗字',
      'Profile': 'プロフィール',
      'Full Name' : 'フルネーム',
      'Company Name': '会社名',
      'Job Title': '役職',
      'Email' : 'Eメール',
      'EmailMessage': '電子メール ドメインは、オンライン アクティビティのメンバーシップの所属を決定するために使用され、変更することはできません。新しいアドレスでメールを受信したい場合は、このアカウントを閉じて、新しいアカウントを作成してください。',
      'Account Options': 'アカウントオプション',
      'Language': '言語',
      'Language Preference': '言語の設定',
      'LanguageMessage' : '言語を変更してください。',
      'Privacy Policy': 'プライバシーポリシー',
      'Privacy Description': '当社のプライバシー ポリシーを確認し、同意します。',
      'Membership' :"会員権",
      'Membership Type':"会員の種類",
      'Membership Status': "会員ステータス",
      'Member Company': "会員企業",
      'Expiration Date': "有効期限",
      'Address': "住所",
      'Home Address': "自宅住所",
      'Update': 'アップデート',
      'Phone' :'電話',
      'Update Profile' : 'プロフィールを更新する',
      'Update Membership': 'メンバーシップを更新する',
      'Company' : '会社',
      'Member Dues Paid Through' :'支払われた会員会費',
      'Update Company' : '会社を更新する',
      'Update Address' : '住所の更新',
      'Addressee' : '宛先',
      'Address Line' : '住所欄',
      'City' : '市',
      'Country / Region' : '国 / 地域',
      'State / Province' : '州',
      'Zip Code' : '郵便番号',
      'Update Phone' : '電話を更新する',
      'Standards Committees' : '基準委員会',
      'Update Affiliate' : 'アフィリエイトを更新する',
      'Select an Affiliate' : 'アフィリエイトを選択してください',
      'Dont see your affiliate?' : 'アフィリエイトが表示されない場合は、',
      'Add one here' : 'ここに 1 つ追加します',
      'Affiliate Message' : '開始するには、関連会社を選択してください。',
      'Add Affiliate' : 'アフィリエイトを追加',
      'Affiliate Name' : 'アフィリエイト名',
      'Submit' : '提出する',
      'Form Error': '上記の必須フィールドに入力または修正してください',
      'Required Error' :'この項目は必須です',


      // States/Provinces
      'Hokkaidō': '北海道',
      'Aomori': '青森県',
      'Iwate': '岩手県',
      'Miyagi': '宮城県',
      'Akita': '秋田県',
      'Yamagata': '山形県',
      'Fukushima': '福島県',
      'Ibaraki': '茨城県',
      'Tochigi': '栃木県',
      'Gunma': '群馬県',
      'Saitama': '埼玉県',
      'Chiba': '千葉県',
      'Tokyo': '東京都',
      'Kanagawa': '神奈川県',
      'Niigata': '新潟県',
      'Toyama': '富山県',
      'Ishikawa': '石川県',
      'Fukui': '福井県',
      'Yamanashi': '山梨県',
      'Nagano': '長野県',
      'Gifu': '岐阜県',
      'Shizuoka': '静岡県',
      'Aichi': '愛知県',
      'Mie': '三重県',
      'Shiga': '滋賀県',
      'Kyoto': '京都府',
      'Osaka': '大阪府',
      'Hyōgo': '兵庫県',
      'Nara': '奈良県',
      'Wakayama': '和歌山県',
      'Tottori': '鳥取県',
      'Shimane': '島根県',
      'Okayama': '岡山県',
      'Hiroshima': '広島県',
      'Yamaguchi': '山口県',
      'Tokushima': '徳島県',
      'Kagawa': '香川県',
      'Ehime': '愛媛県',
      'Kochi': '高知県',
      'Fukuoka': '福岡県',
      'Saga': '佐賀県',
      'Nagasaki': '長崎県',
      'Kumamoto': '熊本県',
      'Ōita': '大分県',
      'Miyazaki': '宮崎県',
      'Kagoshima': '鹿児島県',
      'Okinawa': '沖縄県',
    }
  },
  'ko_KR': {
    translation: {
      'Local First Name': '지역 이름',
      'Local Last Name': '지역 성',
      'Local Company': '현지 회사',
      'Local Division': '지역구분',

      'Title': '제목',
      'First Name': '이름',
      'Last Name': '성',
      'Profile': '프로필',
      'Full Name' : '성명',
      'Company Name': '회사 이름',
      'Job Title': '직위',
      'Email' : '이메일',
      'EmailMessage': '이메일 도메인은 온라인 활동에 대한 회원가입 여부를 결정하는 데 사용되며 변경할 수 없습니다. 새 주소로 이메일을 받으려면 이 계정을 폐쇄하고 새 계정을 만드세요.',
      'Account Options': '계정 옵션',
      'Language': '언어',
      'Language Preference': '언어 기본 설정',
      'LanguageMessage' : '언어를 변경하세요.',
      'Privacy Policy': '개인 정보 정책',
      'Privacy Description': '당사의 개인정보 보호정책을 확인하고 동의하세요.',
      'Membership' :"회원",
      'Membership Type':"회원 유형",
      'Membership Status': "회원상태",
      'Member Company': "회원사",
      'Expiration Date': "만료일",
      'Address': "주소",
      'Home Address': "집 주소",
      'Update': '업데이트',
      'Phone' :'핸드폰',
      'Update Profile' : '프로필 업데이트',
      'Update Membership': '멤버십 업데이트',
      'Company' : '회사',
      'Member Dues Paid Through' :'회원 회비 납부 방법',
      'Update Company' : '회사 업데이트',
      'Update Address' : '주소 업데이트',
      'Addressee' : '수신인',
      'Address Line' : '주소 라인',
      'City' : '도시',
      'Country / Region' : '국가/지역',
      'State / Province' : '주/도',
      'Zip Code' : '우편 번호',
      'Update Phone' : '전화 업데이트',
      'Standards Committees' : '표준위원회',
      'Update Affiliate' : '제휴사 업데이트',
      'Select an Affiliate' : '제휴사를 선택하세요',
      'Dont see your affiliate?' : '귀하의 제휴사가 보이지 않습니까?',
      'Add one here' : '여기에 하나 추가',
      'Affiliate Message' : '제휴 메시지',
      'Add Affiliate' : '제휴사 추가',
      'Affiliate Name' : '계열사 이름',
      'Submit' : '제출하다',
      'Form Error': '위의 필수 입력란을 작성하거나 수정하세요.',
      'Required Error' :'이 필드는 필수입니다.',
    }
  }
};
