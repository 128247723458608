import {conf} from '../config';
import {Auth0User} from './Models';
import auth0, {Auth0Error, WebAuth} from 'auth0-js';
import {HttpError} from './HttpService';
import {HTTP_ERROR_CODE} from './HttpTypes';
import Util from '../util/util';

export class Auth0Service {
  private static userModel: Auth0User | null = null;
  private static auth0UserPromise: Promise<Auth0User> | null = null;

  private static webAuth: WebAuth = new auth0.WebAuth({
    domain: conf.auth0.domain,
    clientID: conf.auth0.clientId,
    responseType: conf.auth0.responseType,
    redirectUri: window.location.origin
    // redirectUri: window.location.origin + conf.staticRoot,
  });

  public static login(): void {
    Auth0Service.webAuth.authorize();
  }

  public static logout(): void {
    Auth0Service.webAuth.logout({
      returnTo: window.location.origin
    });
  }

  public static isLoggedIn(): Promise<boolean> {
    return Auth0Service.getUser().then((u: Auth0User) => {
      return !!u;
    }).catch(() => {
      return false;
    });
  }

  public static parseAuth0Callback(): void {
    // Silently disregard it, because it will be fetched later in checkSession.
    Util.navToRoute(conf.hash.account);
  }

  public static getUser(): Promise<Auth0User> {
    if (Auth0Service.userModel) {
      return Promise.resolve(Auth0Service.userModel);
    }

    if (Auth0Service.auth0UserPromise) {
      return Auth0Service.auth0UserPromise;
    }

    Auth0Service.auth0UserPromise = new Promise((resolve, reject) => {
      Auth0Service.webAuth.checkSession({}, (err: null | Auth0Error, authResult: Auth0User): void => {
          Auth0Service.auth0UserPromise = null;

          if (!err && authResult) {
            if (!authResult.idTokenPayload || !authResult.idTokenPayload['https://semi.org/ams_id']) {
              reject(new HttpError(HTTP_ERROR_CODE.UNAUTHORIZED, 'ams_id missing'));
              return;
            }

            Auth0Service.userModel = authResult;
            resolve(authResult);
            return;
          }

          if (err && err.code && err.description) {
            Auth0Service.userModel = null;
            reject(new HttpError(HTTP_ERROR_CODE.UNAUTHORIZED, 'Error fetching user: ' + err.description));
            return;
          }

          reject(new HttpError(HTTP_ERROR_CODE.NOT_FOUND, 'Error contacting auth0.'));
          return;
        }
      );
    });

    return Auth0Service.auth0UserPromise;
  }

}
