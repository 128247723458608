import React from 'react';
import './TableComponent.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CustomerDownloadModel } from '../../services/Models';


type Props = {
    data?: string;
    value: CustomerDownloadModel[];
};
type State = {};
export default class TextAreaComponent extends React.Component<Props, State> {
    public state: State = {};

    public render(): JSX.Element {

        return (<TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell component="th" scope="row"><b>Document Name</b></TableCell>
                        <TableCell align="right"><b>Publish Date</b></TableCell>
                        <TableCell align="right"><b>Action</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.value.map((row) => (
                        <TableRow key={row.downloadId}>
                            <TableCell component="th" scope="row">{row.docName}</TableCell>
                            <TableCell align="right">{row.publishDate}</TableCell>
                            <TableCell align="right"><a className="btn active btn-outline-success" href={row.downloadLink}>
                                Download</a></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        )
    }
}
