import React from "react";
import "./PhoneFormComponent.scss";
import { ProfileModel } from "../../../services/Models";
import InputComponent from "../../common/InputComponent";
import Validators from "../../../util/validators";
import FormComponent from "../../common/FormComponent";
import { TFunction } from "i18next";
import { Translation } from "react-i18next";

interface FieldErrors {
  phone?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class PhoneFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });
  }

  private validate = (): boolean => {
    const c = this.state.editedProfile.customer;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isValidPhone(c.phone)) {
      fieldErrors.phone = Validators.phoneError;
    }

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    const c = this.state.editedProfile.customer;
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div id="phoneFormComponent">
              <FormComponent
                title={t("Update Phone")}
                buttonText={t("Update Phone")}
                validate={this.validate}
                onSave={(): Promise<void> => {
                  return this.props.onSave(this.state.editedProfile);
                }}
              >
                <InputComponent
                  type="tel"
                  label={t("Phone")}
                  value={c.phone || ""}
                  error={this.state.fieldErrors.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, phone: e.target.value } } });
                  }}
                />
              </FormComponent>
            </div>
          );
        }}
      </Translation>
    );
  }
}
