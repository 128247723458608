import React from 'react';
import './PrivacyPolicyFormComponent.scss';
import {ProfileModel} from '../../../services/Models';
import Validators from '../../../util/validators';
import FormComponent from '../../common/FormComponent';
import CheckboxComponent from '../../common/CheckboxComponent';
import { TFunction } from "i18next";
import { Translation } from "react-i18next";

interface FieldErrors {
  PrivacyOne?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class PrivacyPolicyFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {}
  };

  public componentDidMount(): void {
    this.setState({editedProfile: this.props.profileModel});
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
    return <div id="privacyPolicyFormComponent">
      <FormComponent
          title={'Update Privacy Policy and Terms of Use'}
          buttonText="Update Policy and Terms" validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.editedProfile);
          }}
      >
        <div className="form-row">
          <div>
            <h5>Privacy Policy</h5>
            <p>EFFECTIVE DATE: May 25, 2018</p>
            <p>In this Privacy Policy ("Policy"), we describe how SEMI ("SEMI" or the "Company") of 673 S Milpitas
              Blvd, Milpitas, CA 95035 collects, uses, and discloses information that we obtain about visitors to our
              website,
              www.semi.org (the "Site"), users of our mobile Application (our "App"), and the services available through
              our
              Site and App (collectively, the "Services"), and how we use and disclose that information.&nbsp; SEMI's
              representative in the European Union is SEMI Europe of Helmholtzstrasse 2-9<br/>
              House D / 3rd floor, 10587 Berlin, Germany.</p>
            <p>By visiting the Site, using or downloading our App, or otherwise using our Services, you
              acknowledge&nbsp;that
              your personal information will be handled as described in this Policy. Your use of our Site, App, or
              Services,
              and any dispute over privacy, is subject to this Policy and our Terms of Service, including its applicable
              limitations on damages and the resolution of disputes. Our<a
                  href="https://www.semi.org/en/About/TermsOfUse"> Terms
                of Service </a>
              are incorporated by reference into this Policy.</p>
            <p>The Information We Collect About You<br/>
              Information We Collect Directly From You<br/>
              Information We Collect Automatically<br/>
              How We Use Your Information<br/>
              How We Share Your Information<br/>
              Our Use of Cookies and Other Tracking Mechanisms<br/>
              Clear GIFs, pixel tags and other technologies<br/>
              Third Party Analytics<br/>
              Third-Party Ad Networks<br/>
              Third-Party Links<br/>
              Security of My Personal Information<br/>
              Access To My Personal Information<br/>
              What Choices Do I Have Regarding Use of My Personal Information?<br/>
              Children Under 13<br/>
              Contact Us<br/>
              Changes to this Policy</p>
            <p>&nbsp;</p>
            <p>The Information We Collect About You</p>
            <p>We collect information about you directly from you, from third parties, and automatically through your
              use
              of our
              Site, App, and Services.</p>
            <p>Information We Collect Directly From You. The scope of information that we collect about you depends on
              your
              interaction with our services. If you register with us, we will collect your:&nbsp; email, country/region,
              language preference, first name, last name, and information about your communications preferences. You
              have
              the
              option to provide us with additional information, including, your title, company, address, phone, fax, job
              function, job level. We also collect information from you when you contact us through our Site or App,
              download
              a whitepaper, submit a paper, attend one of our events, or otherwise interact with our Services.&nbsp; In
              addition, if you make a purchase, such as purchasing access to a webinar, we will also request that you
              provide
              your credit or debit card information and your billing and shipping address.</p>
            <p>Information We Collect Automatically. We automatically collect the following information about your use
              of
              our
              Services through cookies, web beacons, and other technologies, including tracking technologies designed
              for
              mobile devices:&nbsp; your domain name; your browser type and operating system; web pages you view; links
              you
              click; your IP address; the length of time you visit our Site and/or use our Services; and the referring
              URL, or
              the webpage that led you to our Site.&nbsp;</p>
            <p>We also collect the following information about your use of the App:&nbsp; mobile device ID; location
              and&nbsp;language
              information; device name and model; operating system type, name, and version; your activities within the
              App;
              and the length of time that you are logged into our App.&nbsp;</p>
            <p>We may combine this information with other information that we have collected about you, including, where
              applicable, your user name, name, and other personal information.&nbsp; Please see the section "Cookies
              and
              Other Tracking Mechanisms" below for more information.</p>
            <p>Information We Collect From Third Parties/Public Sources. If your employer registers you for one of our
              products
              or Services, then we collect the information that your employer provides to us. Members also may post
              press
              releases on our Site; such releases may contain your name as a contact point.</p>
            <p><strong><em>If You Do Not Provide Information</em></strong> If we need to collect your personal
              information
              for
              legal reasons, or under the terms of a contract or to enter into a contract with you, and you fail to
              provide
              that information then we may be limited as to the Services we can provide and in our performance of the
              contract
              and if there is a legal impact we will seek to explain this further to you]</p>
            <p>How We Use Your Information</p>
            <p>We use your information, including your personal information, for the following purposes:</p>
            <ul>
              <li>To provide our Services to you, to communicate with you about your use of our Services, to respond to
                your
                inquiries, to fulfill your orders, and for other customer service purposes.
              </li>
              <li>To tailor the content and information that we may send or display to you, to offer location
                customization,
                and personalized help and instructions, and to otherwise personalize your experiences while using our
                Services.
              </li>
              <li>As permitted by local law, for marketing and promotional purposes. Specifically, we use your
                information
                to
                send you news and newsletters, special offers, and promotions, or to otherwise contact you about
                products
                or
                information we think may interest you.
              </li>
              <li>As permitted by local law, to gain insight into how users interact with our Services, which may assist
                us in
                determining appropriate advertising on our Site or on third party websites.
              </li>
              <li>To better understand how users access and use our Services, both on an aggregated and individualized
                basis
              </li>
              <li>To improve our Services</li>
              <li>For research purposes, such as to research industry trends related to our Services</li>
            </ul>
            <p>If you are in the European Union (EU), when we process personal data about you in the context of
              our&nbsp;
              activities established in the EU or related to our offering of goods or services to you or our monitoring
              of
              your behavior within the EU ("EU Processing") we will do so only when the law allows us.&nbsp; In most
              cases
              we
              or our third parties will be processing your personal data where it is necessary: (a) for our legitimate
              interests (or those of a third party) where these do not override your own interests, rights and freedoms:
              and
              our legitimate interests are the furtherance of (i) our mission to provide industry stewardship and engage
              our
              members to advance the interests of the global electronics supply chain and (ii) our vision to promote the
              development of the global electronics supply chain and positively influence the growth and prosperity of
              our&nbsp;
              members, advancing the mutual business interests of our membership and promoting a free and open global
              marketplace - including through the development, operation and promotion of our Services; or (b) to
              perform
              a
              contract we have entered into or are about to enter into with you personally; or (c) to comply with legal
              and
              regulatory requirements and requests. In rare, emergency situations we may use your personal data for your
              or
              someone else's vital interests. We generally do not rely on your consent for our EU processing other than
              in
              relation to the use of Cookies (see below) and to send you marketing emails, and we will inform you
              specifically
              when we seek to obtain this from you as well as your ability to withdraw that consent at any time. In some
              cases
              there may be overlap between these different basis.</p>
            <p>Accordingly please note that we may undertake some EU Processing of your personal data without your
              knowledge or
              consent, in compliance with the above, where this is required or permitted by law, but if you would like
              more
              information about our basis of EU Processing please contact us at any time.&nbsp; Unless otherwise
              required
              or
              permitted by law, before we begin EU Processing of your personal data for a purpose unrelated to those
              above, we
              will notify you and explain the legal basis which allows us to do so.</p>
            <p>How We Share Your Information</p>
            <p>We may share your information, including personal information, as follows:</p>
            <ul>
              <li>Users of Our Service. A key component of our Services is to connect you with other industry
                participants. If
                you choose to post a comment to one or our blog postings, then we collect the information that you make
                available through the blog. Any information that you post to our Site will be viewable by other visitors
                to
                our Site.
              </li>
              <li><strong><em>Event Attendees</em>.&nbsp; </strong>If you attend one of our events, we share your
                contact
                information with other attendees.
              </li>
              <li>Affiliates. We disclose the information we collect from you to our affiliates or subsidiaries;
                however,
                if
                we do so, their use and disclosure of your personal information will be subject to this Policy.
              </li>
              <li>Service Providers. We disclose the information we collect from you to third party vendors, service
                providers, contractors or agents who perform functions on our behalf.
              </li>
              <li>Business Transfers. If we are acquired by or merged with another company, if we transfer assets to
                another
                company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from
                you
                to the other company.
              </li>
              <li>In Response to Legal Process. We also may disclose the information we collect from you in order to
                comply
                with the law, a judicial proceeding, court order, or other legal process, such as in response to a court
                order or a subpoena.
              </li>
              <li>To Protect Us and Others. We also may disclose the information we collect from you where we believe it
                is
                necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud,
                situations
                involving potential threats to the safety of any person, violations of our Terms of Use or this Policy,
                or
                as evidence in litigation in which Company is involved.
              </li>
              <li>Aggregate Information. We may share aggregate information about users with third parties for
                marketing,
                advertising, research or similar purposes.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>We provide the Services from the United States where the law may not provide the same level of data or
              privacy
              protection as your home territory.&nbsp; Where, however, we are conducting EU Processing we will ensure
              appropriate safeguards are put in place to protect your personal data including through the use of
              specific
              contracts approved by the European Commission which give personal data the same protection it has in
              Europe
              and
              European Commission adequacy decisions about certain countries, as applicable. You can find further
              information
              about these at <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en">
                https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
            </p>
            <p>&nbsp;</p>
            <p>Our Use of Cookies and Other Tracking Mechanisms</p>
            <p>We and our third party service providers use cookies and other tracking mechanisms to track information
              about
              your use of our Site or Services. We may combine this information with other personal information we
              collect
              from you (and our third party service providers may do so on our behalf).</p>
            <p>Cookies.&nbsp; Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive
              through
              your
              web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our
              Site
              and Services, while others are used to enable a faster log-in process or to allow us to track your
              activities at
              our Site and Service. There are two types of cookies: session and persistent cookies.</p>
            <ul>
              <li>Session Cookies. Session cookies exist only during an online session. They disappear from your
                computer
                when
                you close your browser or turn off your computer. We use session cookies to allow our systems to
                uniquely
                identify you during a session or while you are logged into the Site. This allows us to process your
                online
                transactions and requests and verify your identity, after you have logged in, as you move through our
                Site.
              </li>
              <li>Persistent Cookies. Persistent cookies remain on your computer after you have closed your browser or
                turned
                off your computer. We use persistent cookies to track aggregate and statistical information about user
                activity, and to display advertising both on our Site and on third-party sites.
              </li>
            </ul>
            <p>Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your
              browser
              options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to
              prevent your computer from accepting new cookies, how to have the browser notify you when you receive a
              new
              cookie, or how to disable cookies altogether. Visitors to our Site who disable cookies will be able to
              browse
              certain areas of the Site, but some features may not function.</p>
            <p>Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier,
              similar in
              function to cookies. In contrast to cookies, which are stored on your computer’s hard drive, clear GIFs
              are
              embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in
              connection with our Site to, among other things, track the activities of Site visitors, help us manage
              content,
              and compile statistics about Site usage. We and our third party service providers also use clear GIFs in
              HTML
              e-mails to our customers, to help us track e-mail response rates, identify when our e-mails are viewed,
              and
              track whether our e-mails are forwarded.</p>
            <p>Third Party Analytics. We use automated devices and applications, such as Google Analytics, to evaluate
              use
              of
              our Services. We also may use other analytic means to evaluate our Services. To learn more about Google’s
              privacy practices, please review the Google Privacy Policy at https://www.google.com/policies/privacy/.
              You
              can
              also download the Google Analytics Opt-out Browser Add-on to prevent their data from being used by Google
              Analytics at <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout</a>.
              We
              use these tools to help us improve our Services, performance and user experiences. These entities may use
              cookies and other tracking technologies to perform their services. We do not share your personal
              information
              with these third parties.</p>
            <p><strong>Do Not Track</strong></p>
            <p>Currently, our systems do not recognize browser "do-not-track" requests. You may, however, disable
              certain
              tracking as discussed in this section (e.g., by disabling cookies); you also may opt-out of targeted
              advertising
              by following the instructions in the Third Party Ad Network section.</p>
            <p>Third-Party Ad Networks</p>
            <p>We use third parties such as network advertisers to display advertisements on our Sites, to assist us in
              displaying advertisements on third party websites, and to evaluate the success of our advertising
              campaigns.
              Network advertisers are third parties that display advertisements based on your visits to our Site as well
              as
              other websites. This enables us and these third parties to target advertisements by displaying ads for
              products
              and services in which you might be interested. Third party ad network providers, advertisers, sponsors
              and/or
              traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs
              and
              other technologies to measure the effectiveness of their ads and to personalize advertising content to
              you.
              These third party cookies and other technologies are governed by each third party’s specific privacy
              policy,
              not
              this one. We may provide these third-party advertisers with information about your usage of our Site and
              our
              services, as well as aggregate or non-personally identifiable information about visitors to our Site and
              users
              of our service.</p>
            <p>You may opt-out of many third-party ad networks, including those operated by members of the Network
              Advertising
              Initiative ("NAI") and the Digital Advertising Alliance ("DAA").&nbsp; For more information regarding this
              practice by NAI members and DAA members, and your choices regarding having this information used by these
              companies, including how to opt-out of third-party ad networks operated by NAI and DAA members, please
              visit
              their respective websites: www.networkadvertising.org/optout_nonppii.asp (NAI) and
              www.aboutads.info/choices
              (DAA).</p>
            <p>Opting out of one or more NAI member or DAA member networks (many of which will be the same) only means
              that
              those members no longer will deliver targeted content or ads to you. It does not mean you will no longer
              receive
              any targeted content or ads on our Site or other websites. You may continue to receive advertisements, for
              example, based on the particular website that you are viewing. Also, if your browsers are configured to
              reject
              cookies when you visit this opt-out page, or you subsequently erase your cookies, use a different computer
              or
              change web browsers, your NAI or DAA opt-out may no longer be effective. Additional information is
              available
              on
              NAI’s and DAA’s websites accessible by the above links.</p>
            <p>User Generated Content</p>
            <p>We invite you to post content on our Site, including your comments, pictures, and any other information
              that you
              would like to be available on our Site. If you post content to our Site, all of the information that you
              post
              will be available to all visitors to our Site. If you post your own content on our Site or Services, your
              posting may become public and Company cannot prevent such information from being used in a manner that may
              violate this Policy, the law, or your personal privacy.</p>
            <p>Third-Party Links</p>
            <p>Our Site and Services may contain links to third-party websites. Any access to and use of such linked
              websites is
              not governed by this Policy, but instead is governed by the privacy policies of those third party
              websites.
              We
              are not responsible for the information practices of such third party websites.</p>
            <p>Security of My Personal Information</p>
            <p>We have implemented appropriate technical and organizational measures that are designed to provide an
              appropriate
              level of security taking into account the risks that are presented by the relevant processing, in
              particular
              from accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to
              personal
              data transmitted, stored or otherwise processed.</p>
            <p>You should take steps to protect against unauthorized access to your password, phone, and computer by,
              among
              other things, signing off after using a shared computer, choosing a robust password that nobody else knows
              or
              can easily guess, and keeping your log-in and password private.&nbsp; You may be held legally responsible
              where
              you have failed to take reasonable care resulting in lost, stolen, or compromised passwords or any
              activity
              on
              your account via unauthorized password activity.</p>
            <p>For How Long Will You Use My Personal Information?</p>
            <p>We will retain your personal information as long as necessary for purposes for which it was collected and
              used by
              us, as stated in this Privacy Policy.&nbsp; Further, to the extent permitted by applicable law, we will
              retain
              and use your personal information as necessary to comply with our legal obligations, resolve disputes,
              maintain
              appropriate business records, and enforce our agreements.&nbsp; Please contact us if you require more
              information about how we apply these criteria in specific cases.</p>
            <p>&nbsp;</p>
            <p>Access To My Personal Information</p>
            <p>You may modify personal information that you have submitted by logging into your account and updating
              your
              profile information. Please note that copies of information that you have updated, modified or deleted may
              remain viewable in cached and archived pages of the Site or Application for a period of time.</p>
            <p>What Rights and Choices Do I Have Regarding Use of My Personal Information?</p>
            <p>Email:&nbsp; We may send periodic promotional to you (and if you are in the EU we will only do this if
              you
              have
              opted-in to receiving such promotions). You may opt-out of such communications by following the opt-out
              instructions contained in the e-mail. Please note that it may take up to 10 business days for us to
              process
              opt-out requests. If you opt-out of receiving emails about recommendations or other information we think
              may
              interest you, we may still send you e-mails about your account or any Services you have requested or
              received
              from us.</p>
            <p>Special Note to EU Data Subjects:&nbsp; In respect of EU Processing you have the right to: (a) request a
              copy of
              the personal data we hold about you and to check that we are lawfully processing it; (b) request that any
              incomplete or inaccurate personal data we hold about you is corrected; (c) request us to delete or remove
              the
              personal data we hold about you if we do not have a good reason to continue to process it (including if
              you
              successfully object to our processing below or where our processing was unlawful or erasure is required by
              law)
              note that if we cannot comply with your request for legal reasons we will notify these to you; (d) object
              to
              processing of your personal data if we are relying on a legitimate interest (or those of a third party)
              which
              you believe are overridden by your interests, rights and freedoms - although we may be able to demonstrate
              otherwise, or where we are processing your personal data for direct marketing purposes - see how to
              opt-out
              above; (e) request us to suspend the processing of your personal data if: (i) you want us to establish its
              accuracy; (ii) the processing in unlawful but you do not want us to erase it; (iii) you want us to hold it
              in
              order to establish, exercise or defend legal claims; or (iv) you have objected to our use of it but we
              need
              to
              verify whether we have an overriding legitimate grounds to use it; (e) request we transfer your personal
              data to
              you or to a third party in a structured, commonly used, machine-readable format where you provided this
              automated information to us on the basis of consent or to perform a contract with you; and (f) withdraw
              consent
              at any time where we are relying on consent to process your personal data (but without affecting the
              lawfulness
              of any prior processing and subject to us advising you as to the impact if you withdraw your consent).</p>
            <p>If you wish to exercise any of these rights please contact SEMI Europe Helmholtzstrasse 2-9 House D / 3rd
              floor
              10587 Berlin, Germany.&nbsp; You will not have to pay a fee unless your request is clearly unfounded,
              repetitive
              or excessive (or in which case we may refuse to comply). We may need to carry out verification checks
              including
              requesting identity information from you. We seek to comply with legitimate requests within one month but
              complex or multiple requests may take longer but we will keep you updated.</p>
            <p>Children Under 13</p>
            <p>Our Services are not designed for children under 16. If we discover that a child under 16 has provided us
              with
              personal information, we will delete such information from our systems.</p>
            <p>Contact Us</p>
            <p>If you have questions about the privacy aspects of our Services or would like to make a complaint, please
              contact
              us at <a href="mailto:customerservice@semi.org">customerservice@semi.org</a>.</p>
            <p>You also have the right to lodge complaint with a supervisory data protection authority in respect of our
              EU
              Processing.</p>
            <p>Changes to this Policy</p>
            <p>This Policy is current as of the Effective Date set forth above. We may change this Policy from time to
              time. We
              will post any changes to this Policy on our Site. If we make any changes to this Policy that materially
              affect
              our practices with regard to the personal information we have previously collected from you, we will
              endeavor to
              provide you with notice in advance of such change by highlighting the change on our Site, or sending a
              note
              to
              registered users at the email address of record.</p>

          </div>
          <div>
            <h5>Terms of Use</h5>
            <h3>SEMI Privacy Policies and Terms of Use</h3>

            <ul>
              <li><a href="https://www.semi.org/About/TermsOfUse"><strong>SEMI Website Properties Terms of
                Use </strong></a>
              </li>
              <li><a href="https://www.semi.org/en/semi-privacypolicy"><strong>SEMI Website Properties&nbsp;Privacy
                Policy</strong></a></li>
              <li><a href="https://www.semi.org/en/semi-academic-privacy-policy"><strong>SEMI Academic Privacy
                Policy</strong></a>
              </li>
              <li><strong><a href="https://www.semi.org/semi-academic-website-terms-of-use">SEMI Academic Website Terms
                of
                Use</a></strong></li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>SEMI Website Property Terms of Use</strong></p>

            <ul>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#General_Use"><span>General Use Restrictions</span></a></p>
              </li>
              <li>
                <p><a
                    href="https://www.semi.org/en/node/1111#SEMI_Communities"><span>Use of SEMI Online Communities
                    </span></a>
                </p>
              </li>
              <li>
                <p><a
                    href="https://www.semi.org/en/node/1111#3rd_Party_Sites"><span>Links to Third Party Sites</span></a>
                </p>
              </li>
              <li>
                <p><a
                    href="https://www.semi.org/en/node/1111#Password"><span>Password Restricted Areas of the Site
                    </span></a>
                </p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Submissions"><span>Submissions</span></a></p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Warranty"><span>Disclaimer of Warranty</span></a></p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Liability"><span>Limitation of Liability</span></a></p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Local_Laws"><span>Local Laws; Export Control</span></a>
                </p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#General"><span>General</span></a></p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Copyrights"><span>Copyrights and Designated Agent for
                  Notification of Claims of Infringement</span></a>
                </p>
              </li>
              <li>
                <p><a href="https://www.semi.org/en/node/1111#Copyright_Notice"><span>Copyright Notice</span></a></p>
              </li>
            </ul>
            <p><span>THE TERMS AND CONDITIONS SET FORTH BELOW (THE "TERMS") GOVERN YOUR USE OF THIS SITE ON THE WORLD
              WIDE WEB (THE "SITE") OF SEMI, AND ARE LEGALLY BINDING ON YOU. BY USE OF THE SITE OR MATERIALS YOU
              INDICATE YOUR ACKNOWLEDGEMENT AND AGREEMENT WITH THESE TERMS. YOU ARE AGREEING ON BEHALF OF YOURSELF
              AND YOUR EMPLOYER TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS
              OR OTHERWISE USE THIS SITE OR ANY MATERIALS CONTAINED OR MADE AVAILABLE HEREIN.</span>
            </p>

            <p><span><strong>General Use Restrictions</strong></span></p>

            <p><span>All information, documents, products and services provided on this Site (the "Materials"),
              including but not limited to Materials that are downloaded from the Site ("Downloaded Materials"),
              are provided by SEMI or its respective members, manufacturers, developers and vendors (the "Third
              Party Providers"), and are the copyrighted work of SEMI and/or the Third Party Providers, as applicable.
              Materials, and any copies thereof, are licensed, not sold.</span>
            </p>

            <p><span>Subject your compliance with these Terms (including your payment of any applicable fees), SEMI and
              its licensors grant to you the limited, non-exclusive and non-transferable license to display the
              Materials as presented on the Site and, as applicable to the access license you have purchased (as
              further discussed in the Section entitled "Purchases and Access Licenses", below), download, copy,
              reproduce and internally distribute the Materials, provided that: (1) both the SEMI copyright notice
              (set forth below) and these General Use Restrictions appear in the Materials, and (2) such copies are
              solely for your personal or internal informational use (for example, not copied or posted, broadcast
              in any media or otherwise redistributed). You acknowledge and agree that you have no right to modify,
              edit, alter or enhance any of the Materials. In addition, you agree not to "frame" or "mirror" any
              Materials contained on this Site on any other server without the advance written authorization of
              SEMI. The limited license set forth herein terminates automatically, without notice to you, if you
              breach any of these Terms. Upon termination of this limited license, you agree to immediately destroy
              any downloaded and printed Materials.</span>
            </p>

            <p><span>Except as stated herein, you agree that you have no right, title or interest in or to the
              Materials, without the advance written authorization of SEMI or the applicable Third Party Provider.
              No Downloaded Materials or trademarks, logos, graphics or images from the Site may&nbsp;be copied,
              sold, leased, assigned, loaned, transferred, shared through an internal computer network or otherwise
              transferred or distributed without the prior written consent of SEMI. </span>
            </p>

            <p><span><strong>Third Party Content Disclaimer</strong></span></p>

            <p><span>You acknowledge that the Materials may contain certain information that has been compiled by
              SEMI based on information provided by a variety of sources (including information from Third Party
              Providers and publicly available sources) that SEMI believes to be reliable, but which may not be
              accurate or current, and for which SEMI has not necessarily verified accuracy or completeness. You
              acknowledge and agree that the Materials, including any Financial Information therein, are not intended
              to be relied upon for transactional purposes. Reference to any specific commercial products, process,
              or service by tradename, trademark, manufacturer, or otherwise, does not constitute or imply its
              endorsement, recommendation, or favoring by SEMI. The views and opinions of authors expressed in
              the Materials do not necessarily state or reflect those of SEMI.</span>
            </p>

            <p><span>You acknowledge that SEMI is not obligated to monitor any transmissions that occur over the
              Site or "chat room" messages posted to the Site, and you expressly agree that SEMI shall have no
              liability of any kind for any such transmissions or messages. If you believe that a transmission
              or message on the Site violates any applicable laws, you may forward the relevant transmission
              or message to SEMI at legal@semi.org, and SEMI will review the transmission or message, and
              respond accordingly.</span>
            </p>

            <p><span>In your use of the Site, you may enter into correspondence with or participate in promotions of
              advertisers, members or sponsors showing their goods and/or services on the Site. Any such correspondence
              or promotions, including the delivery of and the payment for goods and/or services, and any other terms,
              conditions, warranties or representations associated with such correspondence or promotions, are solely
              between you and the applicable third party. You agree that SEMI assumes no liability, obligation or
              responsibility for any part of any such correspondence or promotion.</span>
            </p>

            <p><span><strong>Use of SEMI Online Communities</strong></span></p>

            <p><span>By accessing and participating in any of SEMI’s Online Communities, you agree: (1) to comply with
              U.S. law regarding the transmission of technical data to outside the United States of America; (2)
              not to use the SEMI Online Communities for illegal purposes; (3) not to interfere or disrupt networks
              connected to the SEMI Online Communities; (4) to comply with all regulations, policies and procedures
              of networks connected to the SEMI Online Communities; and (5) not to use the SEMI Online Communities
              to collect or harvest personal information, including, without limitation, financial information, about
              other users of the SEMI Online Communities.</span>
            </p>

            <p><span>You agree not to transmit through the SEMI Online Communities any unlawful, harassing,
              discriminatory, libelous, defamatory, abusive, threatening, harmful, vulgar, obscene or otherwise
              objectionable material of any kind or nature. You further agree not to transmit any material that
              encourages conduct that could constitute a criminal offense, give rise to civil liability or otherwise
              violate any applicable local, state, national or international law or regulation. Attempts to gain
              unauthorized access to other computer systems are prohibited. You agree not to send transmissions
              through the SEMI Online Communities which attempt to hide your identity or represent yourself as
              someone else. If any information provided by you to the SEMI Online Communities is found to be
              incomplete or inaccurate, SEMI retains the right to terminate your rights to use the SEMI Online
              Communities. You will not interfere with another member’s use and enjoyment of the SEMI Online
              Communities or another entity’s use and enjoyment of similar services.</span>
            </p>

            <p><span>In addition to any other termination rights, SEMI may, at its sole discretion, immediately
              terminate your access to the SEMI Online Communities should your conduct fail to conform with any
              term or condition of this Agreement.</span>
            </p>

            <p><span>It is your responsibility to take precautions to ensure that any information shared in
              the SEMI Online Communities is free of such items as viruses, worms, Trojan horses and other
              items of a destructive nature.</span>
            </p>

            <p><span><strong>Links to Third Party Sites</strong></span></p>

            <p><span>This Site may be linked to other sites on the World Wide Web or the Internet which are
              not under the control of or maintained by SEMI. Such links do not constitute an endorsement by
              SEMI of those sites. You acknowledge and agree that your use of such third-party sites may be
              governed by different terms and conditions and/or privacy policies than SEMI and you hereby agree
              to comply with any and all terms and conditions and privacy policies of such third-party sites.&nbsp;
              You acknowledge that SEMI is providing these links to you only as a convenience, and agree that SEMI
              is not responsible for the content of those sites.</span>
            </p>

            <p><span><strong>Password Restricted Areas of the Site</strong></span></p>

            <p><span>Certain Materials, functionality and areas of the Site are password restricted. To access
              such password-restricted areas, functionality and Materials, you must successfully register an
              account with SEMI. You agree that you are entirely responsible for maintaining the confidentiality
              of your or your employees’ account information and passwords, and agree to notify SEMI if any such
              password is lost, stolen, disclosed to an unauthorized third party, or is otherwise compromised.
              You agree that you are entirely responsible for any and all activities which occur under your account.
              You agree to immediately notify SEMI of any unauthorized use of your account or any other breach of
              security known to you.</span>
            </p>

            <p><span><strong>Purchases and Access Licenses.</strong></span></p>

            <p><span>If applicable, you agree to pay all applicable fees or charges to your account based on your
              use of the Site and the Materials and billing terms set forth on the invoice or the SEMI order form
              used in connection with the purpose.&nbsp; You are responsible for payment of all taxes associated
              with the license of any Materials (except those taxes based on SEMI’s income).&nbsp; You are expressly
              agreeing that SEMI is permitted to invoice you (and/or charge your credit card, if applicable) for all
              applicable fees, taxes and any other charges you may incur in connection with your use of this Site
              and/or the Materials.&nbsp; If you do not pay on time (or, if applicable, if SEMI cannot charge your
              credit card) for any reason, SEMI reserves the right to either suspend or terminate your access to
              your account and terminate your license to access and use the Site and/or Materials.</span>
            </p>

            <p><span>Your ability to download and use certain Downloaded Materials, such as SEMI’s Market Research
              and Market Reports, is limited by the type of access license you purchase.&nbsp; Specifically, SEMI
              offers three types of access licenses:</span>
            </p>

            <ul>
              <li>
                <p><span>Single Edition – If you have purchased a Single Edition license then, subject to your
                  compliance with these Terms, including, without limitation, your payment of all applicable fees,
                  SEMI grants you an additional limited, revocable, non-transferable non-exclusive, non-sublicensable
                  license to download and reproduce a single copy of the applicable Downloaded Materials in the
                  manner and file format(s) that we permit.&nbsp; A Single Edition license may be used by only
                  one person and is not transferrable, even within your organization. You may use the Downloaded
                  Materials solely for your internal business purposes and agree that you will not disclose
                  the Downloaded Materials to any other person. </span>
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>

            <ul>
              <li>
                <p><span>Multi-User – If you have purchased a Multi-User license then, subject to your compliance
                  with these Terms, including, without limitation, your payment of all applicable fees, SEMI grants
                  you an additional limited, revocable, non-transferable non-exclusive, non-sublicensable license
                  to access the applicable database and/or download and reproduce copies of the applicable
                  Downloaded Materials in the manner and file format(s) that we permit, for use by the number
                  of users specified in your Multi-User license.&nbsp; You may use the Downloaded Materials
                  solely for your internal business purposes and agree that you will not disclose the Downloaded
                  Materials to any other person other than those users for whom you have purchased licenses or
                  publish the Downloaded Materials in any manner accessible to third parties.</span>
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>

            <ul>
              <li>
                <p><span>Enterprise – If you have purchased an Enterprise license then, subject to your
                  compliance with these Terms, including, without limitation, your payment of all applicable
                  fees, SEMI grants you an additional limited, revocable, non-transferable non-exclusive,
                  non-sublicensable license to access the applicable database and/or download and reproduce
                  copies of the applicable Downloaded Materials in the manner and file format(s) that we
                  permit for use by anyone within your organization.&nbsp; You may use the Downloaded Materials
                  solely for your internal business purposes and agree that you will not disclose the Downloaded
                  Materials outside of your organization or publish the Downloaded Materials in any manner
                  accessible to third parties.</span>
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>

            <p><span>You shall not: (i) modify or create derivative works of the Downloaded Materials; (ii)
              incorporate the Downloaded Materials in any product that results in a re-distribution or re-use
              of the Downloaded Materials; (iii) remove, obscure or alter any trademark, logo or any copyright
              or other intellectual property notice appearing on the Downloaded Materials; or (iv) sub-license,
              sell, rent, lend, assign, or otherwise transfer or distribute the Downloaded Materials or the
              rights granted herein.</span>
            </p>

            <p>&nbsp;</p>

            <p><span><strong>Submissions</strong></span></p>

            <p><span>Unless otherwise explicitly stated, herein, or in the Privacy Policy (below), you agree
              that any information provided by you in connection with this Site are provided on a non-proprietary
              and non-confidential basis. You agree that SEMI shall be free to use or disseminate such
              information on an unrestricted basis for any purpose. You acknowledge that your unauthorized
              use of any Materials contained on this Site may violate copyright laws, trademark laws,
              the laws of privacy and publicity, and certain communications statutes and regulations,
              and agree that your use of the Site will comply with all applicable laws. You acknowledge
              that you are responsible for the information that you submit, and you, not SEMI, have full
              responsibility for such information, including its legality, reliability, appropriateness,
              originality and copyright, and you agree to indemnify and hold SEMI harmless from and against
              any and all costs, expenses, and damages arising from your use of the site, or use under your
              password and account information. You acknowledge that SEMI shall have the right to terminate
              the limited licenses contained in these Terms in the event that you provide any false or misleading
              information during the registration process.</span>
            </p>

            <p><span><strong>Disclaimer of Warranty</strong></span></p>

            <p><span>UNLESS OTHERWISE EXPLICITLY STATED, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
              THE MATERIALS ON THE SITE ARE PROVIDED "AS IS", AND ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES,
              WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED.
              SEMI, FOR ITSELF AND EACH OF THE THIRD PARTY PROVIDERS, MAKES NO REPRESENTATIONS, WARRANTIES, OR
              GUARANTEES AS TO THE QUALITY, SUITABILITY, TRUTH, ACCURACY OR COMPLETENESS OF ANY OF THE MATERIALS
              CONTAINED ON OR OBTAINED THROUGH YOUR USE OF THE SITE. ANY QUESTIONS REGARDING THE MATERIALS SHOULD
              BE DIRECTED TO THE THIRD PARTY PROVIDERS OF SUCH MATERIALS.</span>
            </p>

            <p><span><strong>Limitation of Liability</strong></span></p>

            <p><span>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SEMI SHALL NOT BE LIABLE FOR ANY DAMAGES
              SUFFERED AS A RESULT OF USING, MODIFYING, CONTRIBUTING, COPYING, DISTRIBUTING, OR DOWNLOADING THE
              MATERIALS. IN NO EVENT SHALL SEMI BE LIABLE FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE,
              SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER
              ECONOMIC ADVANTAGE) HOWEVER ARISING, WHETHER FOR BREACH OR IN TORT, EVEN IF SEMI HAS BEEN PREVIOUSLY
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.</span>
            </p>

            <p><span>YOU AGREE THAT YOU HAVE SOLE RESPONSIBILITY FOR ADEQUATE PROTECTION AND BACKUP OF DATA AND/OR
              EQUIPMENT USED IN CONNECTION WITH THE SITE AND WILL NOT MAKE A CLAIM OF ANY NATURE AGAINST SEMI FOR
              LOST DATA, RE-RUN TIME, INACCURATE OUTPUT, WORK DELAYS OR LOST PROFITS RESULTING FROM THE USE OF THE
              MATERIALS.</span>
            </p>

            <p><span>YOU AGREE TO HOLD SEMI ENTIRELY HARMLESS FROM, AND YOU COVENANT NOT TO SUE SEMI, OR ITS
              AFFILIATES, SUCCESSORS OR ASSIGNS, FOR, ANY CLAIMS RELATED TO THE SITE AND/OR THE MATERIALS.</span>
            </p>

            <p><span><strong>Local Laws; Export Control</strong></span></p>

            <p><span>SEMI controls and operates this Site from its headquarters in the United States of
              America and makes no representation that these Materials are appropriate or available for use
              in other locations. If you use this Site from outside the United States of America, you are
              responsible for compliance with applicable local laws including but not limited to the export
              and import regulations of other countries in relation to the Materials.</span>
            </p>

            <p><span>Unless otherwise explicitly stated, all marketing or promotional materials found on this
              Site are solely directed to individuals, companies or other entities located in the United States.</span>
            </p>

            <p><span>You acknowledge and agree that Materials may be subject to the U.S. Export Administration
              Laws and Regulations, and that diversion of such Materials contrary to U.S. law is prohibited.
              You agree that none of the Materials, nor any direct product therefrom, is being or will be
              acquired for, shipped, transferred, or re-exported, directly or indirectly, to proscribed or
              embargoed countries or their nationals, nor be used for nuclear activities, chemical biological
              weapons, or missile projects, unless specifically authorized by the U.S. Government for such
              purposes. You agree to comply strictly with all U.S. export laws and assume sole responsibility
              for obtaining licenses to export or re-export as may be required.</span>
            </p>

            <p><span><strong>General</strong></span></p>

            <p><span>You acknowledge and agree that this Site and/or the Materials may include certain
              inaccuracies or typographical errors which may affect the quality of the Materials. In addition,
              you agree that SEMI and the Third Party Providers may make improvements and/or changes in the
              products, services, programs, and prices described in this Site, if any, at any time without
              notice, and further agree that SEMI can revise these Terms at any time without notice by updating
              this posting. You agree that your continued use of the Site constitutes your acceptance of such
              revised Terms; provided that if you have purchased a license to use Downloaded Materials, your
              license to use such Downloaded Materials shall remain the version you accepted at the time you
              purchased your license.</span>
            </p>

            <p><span>Any action related to these Terms will be governed by California law and controlling
              U.S. federal law, without regard to the choice or conflicts of law provisions of any jurisdiction.
              You agree to submit to the jurisdiction of the courts located in Santa Clara County, in the
              State of California, for the resolution of all disputes arising from or related to these Terms
              and/or your use of the Site.</span>
            </p>

            <p><span>If any provision of these Terms is held by a court of competent jurisdiction to be contrary
              to law, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions
              of the parties, with all other provisions remaining in full force and effect. The failure of SEMI
              to enforce any right or provision in these Terms shall not constitute a waiver of such right or
              provision unless acknowledged and agreed to by SEMI in writing. The Terms comprise the entire
              agreement between you and SEMI and supersede all prior or contemporaneous agreements between
              the parties regarding the subject matter contained herein.</span>
            </p>

            <p>
              <span><strong>Copyrights and Designated Agent&nbsp;For Notification of Claims of Infringement
              </strong></span>
            </p>

            <p><span>SEMI respects the intellectual property of others, and we ask our users to do the same.
              If you believe that your work has been copied and posted on this Site in a way that constitutes
              copyright infringement, please provide SEMI’s Designated Agent the following information:</span>
            </p>

            <p><span>SEMI’s Policy Concerning Claims of Infringement:</span></p>

            <p><span>A Written Notice of Copyright Claims of infringement should be directed to SEMI’s designated
              agent as specified below. Please include the following information:</span>
            </p>

            <ul>
              <li>
                <p><span>A signature of a person authorized to act on behalf of the owner of the copyright interest
                  that is allegedly infringed;</span>
                </p>
              </li>
              <li>
                <p><span>A description of the copyrighted work that you claim has been infringed;</span></p>
              </li>
              <li>
                <p><span>A description of where the material that you claim is infringing is located on the site;</span>
                </p>
              </li>
              <li>
                <p><span>Your address, telephone number, and email address;</span></p>
              </li>
              <li>
                <p><span>A statement by you that you have a good faith belief that the disputed use is not
                  authorized by the copyright owner, its agent, or the law;</span>
                </p>
              </li>
              <li>
                <p><span>A statement by you, made under penalty of perjury, that the above information in your
                  Notice is accurate and that you are the copyright owner or authorized to act on the copyright
                  owner’s behalf.</span>
                </p>
              </li>
            </ul>
            <p><span>SEMI’s Designated Agent for Notice of claims of copyright infringement:</span></p>

            <p>
        <span>
            SEMI Legal&nbsp;<br/>
            673 South Milpitas Blvd.<br/>
            Milpitas, CA 95035, USA
        </span>
              <br/>
              <span>Phone: 1.408.943.6900&nbsp;</span><br/><span>Email:&nbsp;<a
                href="mailto:legal@semi.org">legal@semi.org</a></span></p>

            <p><span>SEMI’s Policy Concerning Repeat Infringers:</span></p>

            <p><span>Under the appropriate circumstances, it is SEMI’s policy to remove and/or to disable access
              from SEMI to web pages of repeat infringers, to terminate subscribers and account holders who are
              repeat infringers, and to remove and/or to disable access from SEMI to web pages as to which there
              have been steps taken for the purpose of affecting SEMI’s search results such as adding inappropriate
              "meta-tags."</span>
            </p>

            <p><span><strong>Copyright Notice</strong></span></p>

            <p><span>Copyright 2019, © SEMI. All rights reserved.</span></p>
          </div>
        </div>

        <div className="mt-5 text-center">
          <CheckboxComponent
              label="I agree with the above policy and terms of use."
              checked={this.state.editedProfile.customer.privacyAgreed}
              error={this.state.fieldErrors.PrivacyOne}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                this.setState({editedProfile: {...p, customer: {...p.customer, privacyAgreed: e.target.checked}}});
              }}
          />
        </div>

      </FormComponent>
    </div>;
  }}
  </Translation>
);
  }

  private validate = (): boolean => {
    const p = this.state.editedProfile;
    const fieldErrors: FieldErrors = {};

    if (!p.customer.privacyAgreed) {
      fieldErrors.PrivacyOne = Validators.requiredError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
