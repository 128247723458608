import React from 'react';
import './PasswordFormComponent.scss';
import Validators from '../../../util/validators';
import FormComponent from '../../common/FormComponent';
import {PasswordModel} from '../../../services/Models';
import InputComponent from '../../common/InputComponent';

interface FieldErrors {
  PasswordA?: string;
  PasswordB?: string;
}

type Props = {
  onSave: (passwordModel: PasswordModel) => Promise<void>;
};
type State = {
  fieldErrors: FieldErrors;
  passwordA: string;
  passwordB: string;
};
export default class PasswordFormComponent extends React.Component<Props, State> {
  public state: State = {
    fieldErrors: {},
    passwordA: '',
    passwordB: '',
  };

  public render(): JSX.Element {
    return <div id="passwordFormComponent">
      <FormComponent
          title={'Update Password'}
          buttonText="Update Password" validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave({password: this.state.passwordA});
          }}
      >

        <InputComponent
            label="Password"
            value={this.state.passwordA}
            error={this.state.fieldErrors.PasswordA}
            type="password"
            id="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              this.setState({passwordA: e.target.value});
            }}/>

        <InputComponent
            label="Confirm Password"
            value={this.state.passwordB}
            error={this.state.fieldErrors.PasswordB}
            type="password"
            id="confirmPassword"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              this.setState({passwordB: e.target.value});
            }}/>

      </FormComponent>
    </div>;
  }

  private validate = (): boolean => {
    const fieldErrors: FieldErrors = {};

    if (!Validators.isValidPassword(this.state.passwordA)) {
      fieldErrors.PasswordA = Validators.passwordError;
    }

    if (!Validators.isValidPassword(this.state.passwordB)) {
      fieldErrors.PasswordB = Validators.passwordError;
    }

    if (this.state.passwordA !== this.state.passwordB) {
      fieldErrors.PasswordB = Validators.passwordMatchError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
