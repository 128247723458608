import React from 'react';
import './LanguageFormComponent.scss';
import {ProfileModel} from '../../../services/Models';
import Validators from '../../../util/validators';
import FormComponent from '../../common/FormComponent';
import SelectComponent from '../../common/SelectComponent';
import {languagesCodesSelect} from '../../../config';

import { TFunction } from "i18next";
import { Translation } from "react-i18next";
interface FieldErrors {
  language?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class LanguageFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {}
  };

  public componentDidMount(): void {
    this.setState({editedProfile: this.props.profileModel});
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
    return <div id="languageFormComponent">
      <FormComponent
          title={'Update Language'}
          buttonText="Update Language" validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.editedProfile);
          }}
      >
        <SelectComponent
            label="Language"
            value={p.customer.language}
            className="form-row pl-5 pr-5"
            // error={this.state.fieldErrors.language}
            error= {t(this.state.fieldErrors.language || "")}
            selectOptions={languagesCodesSelect}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
              this.setState({editedProfile: {...p, customer: {...p.customer, language: e.target.value}}});
            }}
        />

      </FormComponent>
    </div>; }}
      </Translation>
    );
  }

  private validate = (): boolean => {
    const c = this.state.editedProfile.customer;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(c.language)) {
      fieldErrors.language = Validators.requiredError;
    }

    this.setState({fieldErrors: fieldErrors});

    return Object.keys(fieldErrors).length === 0;
  };
}
