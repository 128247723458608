import React from 'react';
import './LoadingErringComponent.scss';
import SpinnerComponent from '../SpinnerComponent';
import ErrorComponent from '../common/ErrorComponent';

type Props = {
  error: string;
  loading: boolean;
};
type State = {};
export default class LoadingErringComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    if (this.props.error) {
      return <div className="emptySpacer pt-5">
        <ErrorComponent message={this.props.error}/>
      </div>;
    }

    if (this.props.loading) {
      return <div className={'emptySpacer'}>
        <div className="spinnerUp1">
          <SpinnerComponent visible={true}/>
        </div>
      </div>;
    }

    return <div className="loadingErringComponent">
      {this.props.children}
    </div>;
  }
}
