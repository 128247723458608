import i18next, {i18n, TFunction} from 'i18next';
import {initReactI18next} from 'react-i18next';
import {translations} from './translations';
import {LoggerService} from '../LoggerService';
import {conf} from '../../config';

const logger = LoggerService.getLogger('i18nService');

export default class I18nService {
  private static i18n: i18n = i18next;
  private static initialized = false;
  private static tFunc: TFunction | null = null;
  private static currentLanguage = '';

  public static init(): void {
    if (I18nService.initialized) {
      return;
    }

    i18next
    .use(initReactI18next)
    .init({
      resources: translations,
      lng: conf.defaultLanguage,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      }
    }).then((tFunc: TFunction) => {
      I18nService.tFunc = tFunc;
      logger.info('i18n Started.');
    }).catch(() => {
      logger.error('Failed to load i18n');
    });

    I18nService.initialized = true;
  }

  public static setLanguage(lang: string): void {
    
    if (lang === I18nService.currentLanguage) {
      return;
    }

    I18nService.currentLanguage = lang;

    I18nService.i18n.changeLanguage(lang).then((_: TFunction) => {
      logger.info('Set language to ' + lang);
    }).catch((err) => {
      logger.error('Failed to update language ' + err);
    });
  }
}
