import React from 'react';
import './CustomerDownloadComponent.scss';
import { CustomerDownloadData, DocumentDataModel } from '../../services/Models';
// import LoadingErringComponent from '../common/LoadingErringComponent';
import CardComponent from '../common/CardComponent';
import { CustomerDownloadService } from '../../services/CustomerDownloadService';
import { HttpError } from '../../services/HttpService';
import { HTTP_ERROR_CODE } from '../../services/HttpTypes';
import { conf } from '../../config';
import CardFieldRowComponent from '../common/CardFieldRowComponent';
import TableComponent from '../common/TableComponent'
import * as H from 'history';
import Util from '../../util/util';
import ErrorComponent from '../common/ErrorComponent';
import SpinnerComponent from '../SpinnerComponent';
// import classes from '*.module.css';

type Props = {
  onSave: (customerDownloadData: CustomerDownloadData) => Promise<void>;
  location: H.Location;
}
type State = {
  spinnerVisible: boolean;
  documentData: DocumentDataModel[] | null;
  editCustDownloadData: CustomerDownloadData;
  httpError: string;
  customerDownloadData: DocumentDataModel | null;
}

export default class CustomerDownloadComponent extends React.Component<Props, State> {
  public state: State = {
    spinnerVisible: false,
    documentData: null,
    editCustDownloadData: {
      email: Util.getHashByIndex(this.props.location.pathname, 2),
      accessCode: Util.getHashByIndex(this.props.location.pathname, 3),
    },
    httpError: '',
    customerDownloadData: null
  };

  public render(): JSX.Element {
    const customerDownloadData = this.state.customerDownloadData;
    // if (this.state.httpError) {
    //   return <LoadingErringComponent
    //     error={this.state.httpError}
    //     loading={this.state.documentData === null}
    //   />;
    // }
    return <div id="customerDownloadComponent" className='container mt-3'>
      <div className='row'>
        <div className='col-7 col-lg-7'>
          <CardComponent
            title='Product Downloads'
            buttonText='Submit'
            onButtonClick={this.filterCustomerDownload}>
            <div className="row col-12 col-lg-12">
              <div className="row col-12 col-lg-12">
                <div className='row col-4 col-lg-4 col-md-12 col-sm-12'>
                  <label className='customerDownloadLabel' htmlFor='email'>Email Address:</label>
                </div>
                <div className='row col-9 col-lg-9 col-md-12 col-sm-12'>
                  <input
                    id='email'
                    name='email'
                    placeholder=''
                    type='text'
                    value={this.state.editCustDownloadData.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      this.setState({
                        editCustDownloadData: {
                          ...this.state.editCustDownloadData,
                          email: e.target.value.trim()
                        }
                      });
                    }}
                    className='form-control w-100 pl-3 pr-3' />
                </div>
              </div>
              <div className="row col-12 col-lg-12"><p></p></div>
              <div className="row col-12 col-lg-12">
                <div className='row col-4 col-lg-4 col-md-12 col-sm-12'>
                  <label className='customerDownloadLabel' htmlFor='accessCode'>Access Code:</label>
                </div>
                <div className='row col-9 col-lg-9 col-md-12 col-sm-12'>
                  <input
                    id='accessCode'
                    name='accessCode'
                    placeholder=''
                    type='text'
                    value={this.state.editCustDownloadData.accessCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      this.setState({
                        editCustDownloadData: {
                          ...this.state.editCustDownloadData,
                          accessCode: e.target.value.trim()
                        }
                      });
                    }}
                    className='form-control w-100 pl-3 pr-3' />
                </div>
              </div>
              <div className="row col-12 col-lg-12"><p></p></div>
            </div>
          </CardComponent>
        </div>
      </div>
      {this.state.httpError ?
        <ErrorComponent
          message={this.state.httpError} /> : <div></div>}
      {this.state.spinnerVisible ?
        (<div className="mb-4">
          <SpinnerComponent visible={this.state.spinnerVisible} />
        </div>)
        : <div className='card-divider-dashed w-100 mb-3' />
      }
      <div className='row'>
        <div className='col-12 col-lg-12'>

          <CardComponent title=''>

            {customerDownloadData ?
              <TableComponent
                value={customerDownloadData.documentDownload} />

              : <React.Fragment>
                <CardFieldRowComponent subtitle={
                  'Please enter email and access code above.'
                } />

                <div className='card-divider-dashed w-100 mb-3' />
              </React.Fragment>
            }

          </CardComponent>
        </div>
      </div>
    </div>

  }
  private filterCustomerDownload = (): void => {
    this.setState({ customerDownloadData: null })
    if (this.state.editCustDownloadData.email !== '' && this.state.editCustDownloadData.accessCode !== '') {
      this.setState({ customerDownloadData: null, httpError: '', spinnerVisible: true });
      CustomerDownloadService.getCustomerDownload(this.state.editCustDownloadData)
        .then((response: DocumentDataModel[]): void => {
          this.setState({
            customerDownloadData: response[0],
            spinnerVisible: false
          });
        })
        .catch((httpError: HttpError): void => {
          if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
            this.setState({
              httpError: conf.authErrMessage,
              spinnerVisible: false
            });
          } else if (httpError.code === HTTP_ERROR_CODE.BAD_REQUEST) {
            console.log(httpError.code)
            console.log(httpError.message)
            this.setState({
              httpError: 'No records exist for that email and access code.',
              spinnerVisible: false
            });
          } else {
            this.setState({
              httpError: 'Error code: ' + httpError.code + '. Error Message: ' + httpError.message,
              spinnerVisible: false
            });
          }
        });
    } else {
      this.setState({
        customerDownloadData: null
      });

    }
  };

}
