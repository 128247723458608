import React from 'react';
import './HomeComponent.scss';
type Props = {}
type State = {}

export default class HomeComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    return <div id="homeComponent">
      <div id="hero" className="bg-light container-fluid py-5">
        <div className="row ">
          <div className="container">
            <div className="row">
              <div id="heroCaption" className="col-12 col-md-6">
                <h1 className="display-4">ABOUT SEMI</h1>
                <p className="lead font-weight-normal">SEMI Is the global industry association representing
                the electronics manufacturing supply chain, connecting over 2,000 member companies and 1.3
                million professionals worldwide.</p>
              </div>
              <div className="col-12 col-md-6" style={{textAlign: "right"}}>
                <a className="btn active btn-outline-success w-75 my-2" href="https://www.semi.org/en/connect">
                  CONNECT</a>
                <a className="btn btn-outline-success w-75 my-2" href="https://www.semi.org/en/collaborate">
                  COLLABORATE</a>
                <a className="btn btn-outline-success w-75 my-2" href="https://www.semi.org/en/news-resources">
                  INNOVATE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="album py-5 bg-light">
        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <p className=""><b>STANDARDS</b></p>
                  <p className="card-text">
                    SEMI Standards form the foundation for innovation in the microelectronics industry.
                    The SEMI Standards process has been used to create more than 1000 industry approved
                     standards and guidelines, based on the work of more than 5,000 volunteers.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <a href="https://www.semi.org/en/collaborate/standards"
                        className="btn btn-outline-primary btn-sm"  role="button" >View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <p className=""><b>MEMBER ONLY</b></p>
                  <p className="card-text">Premium Content for SEMI Members including important industry
                   information and key content from major SEMI events.</p>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <a href="https://www.semi.org/en/news-resources/library?search=&start=&end=&membership%5Bmember%5D=member&sort_bef_combine=created%20DESC"
                        className="btn btn-outline-primary btn-sm"  role="button" >View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <p className=""><b>CONNECT@SEMI</b></p>
                  <p className="card-text">An online, interactive platform for SEMI Technology Community
                   members to communicate, collaborate and manage their activities.</p>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                    <a href="https://www.semi.org/en/collaborate/connect-at-SEMI"
                        className="btn btn-outline-primary btn-sm"  role="button" >View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>;
  }
}
