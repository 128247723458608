import React from 'react';
import './FileComponent.scss';
import Util from '../../util/util';

type Props = {
  label: string;
  placeholder?: string;
  error?: string;
  id?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
type State = {};
export default class FileComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const id = this.props.id || 'upload-' + Util.removeSpaces(this.props.label);

    return <div className="fileComponent form-row pl-2 pr-2 mb-2">
      <div className="form-group w-100">
        <label htmlFor={id} className={(this.props.error ? 'text-danger' : '')}>
        </label>
        <input
          id={id}
          name={id}
          placeholder={this.props.placeholder || this.props.label}
          type="file"
          className={'w-100 ' + (this.props.error ? 'is-invalid' : '')}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          multiple={this.props.multiple}
        />

        <div className={(this.props.error ? '' : 'd-none')}>
          <small className="text-danger">
            {this.props.error}
          </small>
        </div>
      </div>
    </div>;
  }
}
