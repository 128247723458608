import React from 'react';
import './StandardsBallotsComponent.scss';
import {StandardsBallotsData} from '../../services/Models';
import {HttpError} from '../../services/HttpService';
import {conf} from '../../config';
import {Switch, Route, RouteProps} from 'react-router';
import {BallotsService} from '../../services/BallotsService';
import {HTTP_ERROR_CODE} from '../../services/HttpTypes';
import LoadingErringComponent from '../common/LoadingErringComponent';
import AllStandardsBallotsComponent from './standardsBallotsPages/AllStandardsBallotsComponent';
import MyStandardsBallotsComponent from './standardsBallotsPages/MyStandardsBallotsComponent';

type Props = {};
type State = {
  standardsBallotsData: StandardsBallotsData | null;
  httpError: string;
};

export default class StandardsBallotsComponent extends React.Component<Props, State> {
  public state: State = {
    standardsBallotsData: null,
    httpError: '',
  };

  public componentDidMount(): void {
    this.fetchBallots();
  }

  public render(): JSX.Element {
    if (this.state.httpError || this.state.standardsBallotsData === null) {
      return <LoadingErringComponent
        error={this.state.httpError}
        loading={this.state.standardsBallotsData === null}
      />;
    }

    const committeeCycles = this.state.standardsBallotsData.committeeCycles;
    const votingAnnouncements: string[] = this.state.standardsBallotsData.votingAnnouncements;

    return (
      <div id="standardsBallotsComponent">
        <Switch>
          <Route
            path={conf.hash.myStandardsBallots}
            render={(_: Readonly<RouteProps>): JSX.Element => {
              return <MyStandardsBallotsComponent committeeCycles={committeeCycles}/>;
            }}
          />
          <Route
            render={(_: Readonly<RouteProps>): JSX.Element => {
              return (
                <AllStandardsBallotsComponent
                  committeeCycles={committeeCycles}
                  votingAnnouncements={votingAnnouncements}
                />
              );
            }}
          />
        </Switch>
      </div>
    );
  }

  private fetchBallots = (): void => {
    this.setState({standardsBallotsData: null, httpError: ''});

    BallotsService.getStandardsCommitteeCycles()
      .then((response: StandardsBallotsData): void => {
        this.setState({
          standardsBallotsData: response

        });
      })
      .catch((httpError: HttpError): void => {
        if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
          this.setState({httpError: conf.authErrMessage});
        } else {
          this.setState({httpError: httpError.toString()});
        }
      });
  };
}
