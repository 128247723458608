import React from 'react';
import './InputComponent.scss';
import Util from '../../util/util';

type Props = {
  label: string;
  value: string;
  placeholder?: string;
  error?: string;
  type?: string;
  id?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
type State = {};
export default class InputComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label);

    return <div className="inputComponent form-row pl-5 pr-5 mb-2">
      <div className="form-group w-100">
        <label htmlFor={id} className={(this.props.error ? 'text-danger' : '')}>{this.props.label}</label>
        <input
          id={id}
          name={id}
          placeholder={this.props.placeholder || this.props.label}
          type={this.props.type || 'text'}
          className={'form-control w-100 pl-3 pr-3 ' + (this.props.error ? 'is-invalid' : '')}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <div className={(this.props.error ? '' : 'd-none')}>
          <small className="text-danger">
            {this.props.error}
          </small>
        </div>
      </div>
    </div>;
  }
}
