import React from "react";
import "./CloseAccountFormComponent.scss";
import Validators from "../../../util/validators";
import FormComponent from "../../common/FormComponent";
import CheckboxComponent from "../../common/CheckboxComponent";
import { AlertTriangle } from "react-feather";
import { ProfileModel } from "../../../services/Models";

import { TFunction } from "i18next";
import { Translation } from "react-i18next";

interface FieldErrors {
  agreedIsChecked?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
  agreedIsChecked: boolean;
};
export default class CloseAccountFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
    agreedIsChecked: false,
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });
  }

  public render(): JSX.Element {
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div id="closeAccountFormComponent">
              <FormComponent
                title={"Close Account"}
                buttonDanger={true}
                buttonText="Close My Account"
                validate={this.validate}
                onSave={(): Promise<void> => {
                  return this.props.onSave(this.state.editedProfile);
                }}
              >
                <div className="form-row">
                  <blockquote className="blockquote text-center">
                    <p className="mb-0 alert-warning p-4">
                      <AlertTriangle className="mr-2" />
                      Closing your account cannot be undone. Are you sure?
                    </p>
                    <footer className="blockquote-footer">
                      Please allow <cite>5-7 business days</cite> lorem ipsum.
                    </footer>
                  </blockquote>
                </div>

                <CheckboxComponent
                  label="I understand that this cannot be undone."
                  checked={this.state.agreedIsChecked}
                  // error={this.state.fieldErrors.agreedIsChecked}
                  error= {t(this.state.fieldErrors.agreedIsChecked || "")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ agreedIsChecked: e.target.checked });
                  }}
                />
              </FormComponent>
            </div>
          );
        }}
      </Translation>
    );
  }

  private validate = (): boolean => {
    const fieldErrors: FieldErrors = {};

    if (!this.state.agreedIsChecked) {
      fieldErrors.agreedIsChecked = Validators.requiredError;
    }

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };
}
